import React from "react";
import './Technical.css'

function Hidebutton({heading,para,image}){
return(
  <div className="container p-lg-40  groupcontainer">
  <div className="row">
    <div className="col-lg-6 col-md-12">
      <h3 className="whyjin mt-40">{heading}</h3>
      <p className="motivatrfun mt-40">
      {para}
      </p>
    </div>
    <div className="col-lg-6 col-md-12">
    <div className="align-self-center">
      <img src={image} alt="group"  className="img-fluid w-100"/>
    </div>
    </div>
  </div>
</div>
)
}
export default Hidebutton