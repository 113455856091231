import React from "react";
import VideoOverlay from "../component/Homevideo/Videooverlay";
import Homehappyclient from "../component/Homehappy/Homehappyclient";
import HomeBusinessSol from "../component/Homebusiness/HomeBusinessSol";
import WorkCard from "../component/WorkCard/WorkCard";
import Technology from "../component/TechnologiesWork/Technology";
import { Fragment } from "react";
import WorkingModel from "../component/WorkingModel/WorkingModel";
import YourProject from "../component/YourProject/YourProject";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Rirabh - Leading IT Solutions & Software Development Company
        </title>
        <meta
          name="description"
          content="Welcome to Rirabh, your trusted partner for cutting-edge IT solutions and software development services. We deliver innovative, reliable, and scalable solutions tailored to your business needs."
        />
        <meta
          name="keywords"
          content="IT solutions, software development, custom software development, IT consulting, software solutions, technology services, IT company India, enterprise software solutions, software development company, Rirabh, business technology solutions, IT services provider, cloud solutions, digital transformation"
        />
      </Helmet>

      <VideoOverlay />
      <Homehappyclient />
      <HomeBusinessSol />
      <WorkCard />
      <Technology />
      <WorkingModel />
      <YourProject />
    </Fragment>
  );
}

export default Home;
