import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import ivr1 from "../../src/assets/ivr4.svg";
import ivr2 from "../../src/assets/ivr3.svg";
import ivr3 from "../../src/assets/ivr2.svg";
import ivr4 from "../../src/assets/ivr1.svg";
import { Helmet } from "react-helmet";
function PaymentonIVR() {
  return (
    <div>
      <Helmet>
        <title>
        IVR Payment Software Solution | Best IVR Service Providers | Rirabh
        </title>
        <meta
          name="description"
          content="Secure and automate customer payments with advanced IVR payment software solutions, offering seamless, efficient, and reliable transaction processing."/>
        <meta name="keywords" content="IVR payment solution providers in india, top 5 IVR payment solution providers" />
      </Helmet>
    <CustomSDev
      heading="IVR Payment Solutions" para="Simplifying & automating payments through IVR Payment Solutions."
    />
          <CusSoftDevelopment MainHeading="WE OFFER"
        HPara="Utilizing IVR Payment Solutions with Secure Way. "
        h1="Payment via any card:  "
        p1="Give your customers an option to pay with any Debit or Credit Card.    "
        h2="Reduce transaction cost: "
        p2="Leverage payment options that have a lower transactional cost. "
        h3="Improve accessibility:  "
        p3="We improve accuracy across regions or countries. "
        h4="Simplifies compliance:    "
        p4="We try to make the compliances much more comfortable. "
        img1={ivr1}
        img2={ivr2}
        img3={ivr3}
        img4={ivr4}
      />
             <ProcessDevelop
        heading="IVR Payment Implementation Process"
        para="IVR Payment Solution involves several key steps Such as:"
        name1="Setup & Integration"
        name2="Design & Call Flow"
        name3="Authentication & Security"
        name4="Confirmation & Receipts"
        name5="Monitoring & Reporting"
      />
      <WhyChooseUs/>
    </div>
  );
}

export default PaymentonIVR;
  