import customsoftware from "../../assets/Custom Software Development.svg";
import ecommerce from "../../assets/Ecommerce Development.svg";
import mobile from "../../assets/Mobile Application Development.svg";
import uiux from "../../assets/UI UX Design.svg";
import website from "../../assets/Website Design & Development.svg";
import softwarebusiness from "../../assets/softwarebusiness.svg";
import Websitedev from "../../assets/websitedev.svg"
import ecommercedev from "../../assets/ecommercedev.svg"
import UIuxdev from "../../assets/UIuxdev.svg"
import MobileappDev from "../../assets/MobileappDev.svg"


const BusSol = [
  { id:1,
    image: softwarebusiness,
    icon: customsoftware,
    name: "Custom Software Development",
    articlename:
      "We are leveraging our highly oriented skills & knowledge in crafting customer software developments that allow us to streamline processes, enhance overall efficiency, and generate the best outcomes to scale your business. ",
    recentwork: "We offer:",
    achievement1:"We offer the best possible consultation, planning, and strategy and develop excellent designs per the industry trend. " ,
    achievement2:"We have the best testing tools and techniques and are assured of quality delivery. ",
    link:"software-development"
  },
  {  id:2,
    image: MobileappDev,
    icon: mobile,
    name: "Mobile App Development",
    articlename:
      "We have a trending methodology for mobile application development and we ensure the seamless integration efficient process from concept to deployment. Here’s an overview of the comprehensive services .",
    recentwork: "We offer:",
    achievement1:"We have an experienced developer who is well capable of making a perfect strategy to implement the best mobile applications." ,
    achievement2:"We are responsible for the testing, maintenance, and quality also, we provide the best integration techniques. ",
        link:"mobile-app-development"
  },
  {  id:3,
    image: Websitedev,
    icon: website,
    name: "Website Development",
    articlename:
      "Our website development services cover the entire lifecycle of a website project, from initial concept to deployment and ongoing maintenance. Here’s an overview of the comprehensive services .",
    recentwork: "We offer:",
    achievement1:"We design highly oriented websites and apply the latest trends & technology with proper testing." ,
    achievement2:"We are responsible for 360-degree support namely SEO, Digital Marketing, and other maintenance. ",
        link:"web-development",
  },
  { id:4,
    image: UIuxdev,
    icon: uiux,
    name: "UI UX Design",
    articlename:
      "Our UI/UX design services focus on crafting intuitive, engaging, and visually appealing user experiences that meet your business goals and resonate with your target audience. Here’s an overview of the comprehensive services ",
    recentwork: "We offer:",
    achievement1:"We do the best possible research to meet your actual requirements and provide the best design and implementations." ,
    achievement2:"We have an excellent strategy for branding and user engagement as well as retention. ",
        link:"uiux-design"
  },
  { id:5,
    image: ecommercedev,
    icon: ecommerce,
    name: "Ecommerce Development",
    articlename:
      " Our e-commerce web development services are the combination of the best shopping ethics. We build, launch, and maintain a successful online store and generate a seamless shopping experience that drives sales and enhances customer satisfaction. Here’s an overview of the comprehensive services.",
    recentwork: "We offer:",
    achievement1:"We have served various e-commerce websites and we apply the best R&D & planning to make a seamless & smooth shopping cart & check-out. " ,
    achievement2:"We provide the perfect user experience through our modern designs. ",
        link:"ecommerce-development"
  },
];
export default BusSol