import React, { Fragment } from "react";
import CustomSDev from "../component/Parts/CustomSDev";
import Hidebutton from "../component/Career/Hidebutton";
import ourwork from "../assets/OurWork.svg"; 
import RecentWork from "../component/OurWork/RecentWork";
import WorkingModel from "../component/WorkingModel/WorkingModel";
import YourProject from '../component/YourProject/YourProject'

function OurWork() {
  return (
    <Fragment>
    <div style={{ backgroundColor: '#F8F8F8' }}> 
      <CustomSDev
        heading="Showcase of Our Work"
        para="Each piece reflects our dedication to quality, innovation, and excellence."
      />
      <Hidebutton
        heading="I'm here to help!"
        para="Crafting exceptional products that meet and exceed user expectations is a commendable goal. If you have any specific projects or challenges you're currently facing, or if you need advice on any aspect of product development, feel free to share. "
        image={ourwork}
      />
      <RecentWork/>
    </div>
    <WorkingModel/>
    <YourProject/>
    </Fragment>
  );
}

export default OurWork;
