import React from 'react';
import Buttonvideo from '../Homevideo/Buttonvideo';
import './part.css'

function CustomSDev({heading,para}) {
  return (
    <div className='bubble-image container-fluid' >
  
      <p className='customsoftdev'>{heading}</p>
      <p className='buidingexpr2'>{para}</p>
      <div className='d-lg-none d-flex justify-content-center lgbtncustom'>
        <Buttonvideo id='smallvideobtn' classname='smallvideobtnspan ' btnid='lasto'/>
      </div>
      <div className=' d-flex justify-content-center lgbtncustom' id='hide-on-small'>
        <Buttonvideo id='videobtn' classname='videobtnspan'/>
      </div>
      
  
    </div>
  )
}

export default CustomSDev;
