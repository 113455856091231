import React from "react";
import "./Forms.css";

function Forms({ onSubmit }) {


  return (
    <div className="container-fluid margincontainer-form">
      <div className="container containerborderjob  ">
        <form >
          <h3 className="headingpersonal ">Personal Information</h3>
          <div className="row">
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="firstName" className="form-label gformlvl">
                First Name 
              </label>
              <input
                type="text"
                className="form-control "
                id="firstName"
                name="firstName"
             
                placeholder="First Name"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="lastName" className="form-label gformlvl">
                Last Name
              </label>
              <input
                type="text"
                className="form-control "
                id="lastName"
                name="lastName"
               
                placeholder="Last Name"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="email" className="form-label gformlvl">
                Email ID
              </label>
              <input
                type="email"
                className="form-control "
                id="email"
                name="email"
             
                placeholder="Email ID"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="phoneNumber" className="form-label gformlvl">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control "
                id="phoneNumber"
                name="phoneNumber"
             
                placeholder="Phone Number"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="currentPosition" className="form-label gformlvl">
                Current Position
              </label>
              <input
                type="text"
                className="form-control "
                id="currentPosition"
                name="currentPosition"
            
                placeholder="Current Position"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="positionApplying" className="form-label gformlvl">
                Position Applying For
              </label>
              <input
                type="text"
                className="form-control "
                id="positionApplying"
                name="positionApplying"
              
                placeholder="Position Applying For"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="currentCTC" className="form-label gformlvl">
                Current CTC
              </label>
              <input
                type="text"
                className="form-control "
                id="currentCTC"
                name="currentCTC"
             
                placeholder="Current CTC"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="uploadCV" className="form-label gformlvl">
                Upload your CV Here
              </label>
              <input
                type="file"
                className="form-control "
                id="uploadCV"
                name="uploadCV"
            
              />
            </div>

            <h3 className="headingpersonal mt-4">Previous Job Profile</h3>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="jobTitle" className="form-label gformlvl">
                Title
              </label>
              <input
                type="text"
                className="form-control "
                id="jobTitle"
                name="jobTitle"
               
                placeholder="Title"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="company" className="form-label gformlvl">
                Company
              </label>
              <input
                type="text"
                className="form-control "
                id="company"
                name="company"
          
                placeholder="Company"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="industry" className="form-label gformlvl">
                Industry
              </label>
              <input
                type="text"
                className="form-control "
                id="industry"
                name="industry"
               
                placeholder="Industry"
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="jobSummary" className="form-label gformlvl">
                Job Summary
              </label>
              <textarea
                className="form-control "
                id="jobSummary"
                name="jobSummary"
          
                rows="3"
                placeholder="Job Summary"
              ></textarea>
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="startDate" className="form-label gformlvl">
                Start Date
              </label>
              <input
                type="date"
                className="form-control "
                id="startDate"
                name="startDate"

              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="endDate" className="form-label gformlvl">
                End Date
              </label>
              <input
                type="date"
                className="form-control "
                id="endDate"
                name="endDate"

              />
            </div>
            <div className="mb-3 ms-3 form-check ">
              <input
                type="checkbox"
                className="form-check-input"
                id="currentWork"
                name="currentWork"
         
              />
              <label className="form-check-label " htmlFor="currentWork">
              <span className="gformlvl">
              I currently work here
              </span> 
              </label>
            </div>
          </div>
          <hr/>
          <div className="text-center mt-40  mb-40">
          <button type="submit" className="formButton">
            SUBMIT
          </button>
          </div>
      
        </form>
      </div>
    </div>
  );
}

export default Forms;
