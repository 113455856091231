import React from 'react'
import phone from "../../assets/lucide_phone-call.png";
import majectic from "../../assets/majesticons_mail-line.png";
import "./YourProject.css";
import { Fragment } from 'react';

function Projectresp() {
  const handleCallClick=()=>{
window.location.href='tel:0120-605-0220'
  }

  const handleMailClick=()=>{
    window.location.href='mailto:info@rirabh.com'
  }
  return (
    <Fragment>


    <div className="d-flex" id="salesmailbox" onClick={handleMailClick} >
      <img src={majectic} alt="" />
      <div>
        <span className="mailproject">MAIL TO OUR SALES DEPARTMENT</span>
        <br/>
        <span className="salesproject">info@rirabh.com</span>
      </div>
    </div>
    
    <div className="d-flex my-3" id="salesmailbox" onClick={handleCallClick}>
      <img src={phone} alt="" />
      <div>
        <span className="mailproject">Call Us</span>
        <br/>
        <span className="salesproject">0120 605 0220</span>
      </div>
    </div>
    <div className=" pt-40 mb-100">
        <span className="projectwhat">& What you will get:</span>
        <ul>
            <li id="onprojectdetail">On-call inquiry assistance</li>
            <li id="onprojectdetail">Project consulting by experts</li>
            <li id="onprojectdetail">Detailed project estimation</li>
        </ul>
    </div>
  </Fragment>
  )
}

export default Projectresp