import React from 'react'
import CustomSDev from '../component/Parts/CustomSDev'

import Forms from '../component/JobApplicationForm/Forms'

function JobApplicationForm() {
  return (
    <div>
           <CustomSDev
        heading="Get In Touch & Get Started"
        para="Please send us a message and we will get back to you"
      />
   
      <Forms/>
    </div>
  )
}

export default JobApplicationForm