import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import ob1 from '../../src/assets/ob4.svg';
import ob2 from '../../src/assets/ob3.svg';
import ob3 from '../../src/assets/ob2.svg';
import ob4 from '../../src/assets/ob1.svg';
import { Helmet } from "react-helmet";
function OutboundDialer() {
  return (
    <div>
 <Helmet>
        <title>
        Outbound Dialer Software Provider in India | Rirabh
        </title>
        <meta
          name="description"
          content="Find top outbound dialer software providers in India, delivering efficient automated calling solutions to boost sales, customer engagement, and productivity."/>
        <meta name="keywords" content="Outbound dialer software provider in india,  Best outbound dialer software provider in india, auto dialer software for call center" />
      </Helmet>

    <CustomSDev
      heading="Outbound Dialer" para="Building experiences across different the  world."
    />
          <CusSoftDevelopment MainHeading="WE OFFER"
        HPara="Our call center dialer helps you address your customer needs. "
        h1="Reduce Dial-Time Restriction "
        p1="Custom Dialing to specific time zones or regions."
        h2="Dial Order"
        p2="Mapped to contact and increase the Dial Coverage for a business.  "
        h3="Answer Machine Detection  "
        p3="AMD ensures a dialer skips the answering machine. "
        h4="Intelligent Lead Management   "
        p4="Automatically notify the supervisor when the lead list reaches. "
        img1={ob1}
        img2={ob2}
        img3={ob3}
        img4={ob4}
      />
                  <ProcessDevelop
        heading="Outbound Dialer Implementation Process"
        para="Outbound Dialer Solution involves several key steps Such as:"
        name1="Assessment & Planning"
        name2="Development & Customization"
        name3="Testing"
        name4="Deployment"
        name5="Training"
      />
      <WhyChooseUs/>
    </div>
  );
}

export default OutboundDialer;
  