import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import AI1 from "../../src/assets/ai4.svg";
import AI2 from "../../src/assets/ai3.svg";
import AI3 from "../../src/assets/ai2.svg";
import AI4 from "../../src/assets/ai1.svg";
import { Helmet } from "react-helmet";

function APIIntregration() {
  return (
    <div>
  <Helmet>
  <title>API Integration Service Provider Company | Rirabh</title>
  <meta
    name="description"
    content="Unlock seamless connectivity with top API integration service providers, ensuring efficient, reliable, and scalable solutions for your business needs."
  />
  <meta
    name="keywords"
    content="API integration services, API service providers, seamless API integration, scalable API solutions, best API integration company, reliable API integration, API connectivity solutions, API integration experts, custom API integration, API integration company India"
  />
</Helmet>

      <CustomSDev
        heading="API Integration"
        para="Take Your Business to the Next Level. "
      />
      <CusSoftDevelopment
        MainHeading="WE OFFER"
        HPara="Compose new apps using multiple APIs.  "
        h1="Automation:"
        p1="API integration allows the handoff of information.  "
        h2="Scalability:"
        p2="API integration allows businesses to grow unlimited.   "
        h3=" Streamlined Visibility: "
        p3=" API integration allows you end-to-end visibility of all systems. "
        h4="Eliminates the Errors:   "
        p4="The transfer of complex and voluminous data with reduced errors  "
        img1={AI1}
        img2={AI2}
        img3={AI3}
        img4={AI4}
        alt1="API integration Automation"
        alt2="API integration Scalability"
        alt3="API integration Streamlined Visibility"
        alt4="API integration Eliminates the Error"
       
      />
      <ProcessDevelop
        heading="API Integration Implementation Process"
        para="API Integration Solution involves several key steps Such as:"
        name1="Planning & Requirement Analysis"
        name2="System Design & Architecture"
        name3="Testing"
        name4="Deployment"
        name5="Maintenance & Updates"
      />
      <WhyChooseUs />
    </div>
  );
}

export default APIIntregration;
