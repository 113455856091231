import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import YourProject from '../component/YourProject/YourProject'
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import uiux1 from "../../src/assets/uiux1.svg";
import uiux2 from "../../src/assets/uiux2.svg";
import uiux3 from "../../src/assets/uiux3.svg";
import uiux4 from "../../src/assets/uiux4.svg";
import { Helmet } from "react-helmet";
function UiUxDesign() {
  return (
    <div>

<Helmet>
<title>Best UI/UX Design Company in India | Rirabh</title>
        <meta
          name="description"
          content="India's top UI/UX design company, delivering exceptional user experiences with innovative design and cutting-edge technology. Transform your digital presence with us."
        />
        <meta name="keywords" content="ui ux design companies in india, ui ux design services provider in india, ui ux design services, Affordable ui ux design services" />
      </Helmet>
 
    <CustomSDev
      heading="UI-UX DESIGN" para="Crafting UX designs that blend user needs with business goals"
    />
      <CusSoftDevelopment
      MainHeading="WE OFFER"
      HPara="We provide end-to-end UI UX services including research, UI design, and development."
      h1=" Research: "
      p1="We provide data-driven insights that represent the voice of the user and resonate with business objectives."
      h2="Design:"
      p2="Our team of UX-UI designers thrives on creating designs. "
      h3="Build:"
      p3="We help you translate any kind of design into pixel-perfect and adaptable digital products."
      h4="Branding:"
      p4="We have unique strategies to provide effective branding via digital marketing tools." 
        img1={uiux4}
        img2={uiux3}
        img3={uiux2}
        img4={uiux1}

      />
      <ProcessDevelop
         heading="Our Process For UI & UX Design"
        para="The UX design process is structured, iterative, and systematic which is necessary for designing the user experience. "
        name1="Research and Discovery"
        name2="Planning & Strategy"
        name3="Design Implementation and Support"
        name4="Branding and Identity"
        name5="User Engagement and Retention"
      />
      <WhyChooseUs/>
      <YourProject/>
    </div>
  );
}

export default UiUxDesign;
