import React, { useState, useEffect, useRef } from 'react';
import './Listitem.css';
import dropdownItems from './Datadrop';

const ChevronDownIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24">
    <path d="M12 15.6L4.8 8.4 6.2 7l5.8 5.8L17.8 7l1.4 1.4z" fill="white" />
  </svg>
);

function ListItem() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='d-flex' ref={dropdownRef}>
      {dropdownItems.map((item, index) => (
        <div key={index}>
          <span
            className="text-white mx-3"
            type="button"
            onClick={() => toggleDropdown(index)}
            aria-expanded={openDropdown === index}>
            {item.label}
            <ChevronDownIcon className={`chevron-icon ${openDropdown === index ? 'rotate180' : ''}`} />
          </span>
          <ul className={`dropdown-menu dropdown-menu-color rounded-0 mt-3 ${openDropdown === index ? 'd-block' : 'd-none'}`}>
            {item.links.map((link, linkIndex) => (
             <a className='txtdcrnnone' href={link.url}>
              <li key={linkIndex} className='linknamemargin'>
             <p className="linkname ">{link.text}</p>
             </li>
             </a>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default ListItem;
