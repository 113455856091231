import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import YourProject from "../component/YourProject/YourProject";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import ecd1 from "../../src/assets/ecd1.svg";
import ecd2 from "../../src/assets/ecd2.svg";
import ecd3 from "../../src/assets/ecd3.svg";
import ecd4 from "../../src/assets/ecd4.svg";
import { Helmet } from "react-helmet";

function EcommerceDevelopment() {
  return (
    <div>
<Helmet>
<title>E-Commerce Website Development Services Provider | Rirabh</title>
        <meta
          name="description"
          content="Professional eCommerce website development services provider, offering custom design, seamless user experience, and secure payment integration for online success."
        />
        <meta name="keywords" content="e commerce website development services, e-commerce website development service provider, e-commerce website development services in India, e-commerce website development company" />
      </Helmet>
      <CustomSDev
        heading="Ecommerce Development"
        para="Get Engaged, and Results result-oriented E-commerce website with the latest technology"
      />
      <CusSoftDevelopment 
        MainHeading="WE OFFER"
        HPara="Your one-stop destination for unique e-commerce website development services. "
        h1="Custom E-commerce Website Design: "
        p1="Enhance your brand visibility through user-friendly & responsive layouts."
        h2="Platform-Based E-commerce Development: "
        p2="Get complete customization and integration services in one place"
        h3="Mobile Commerce Development: "
        p3="Get Mobile-friendly e-commerce solution mobile applications for both Android and iOS."
        h4="Shopping Cart Development: "
        p4="Get a customized shopping cart with the Integration of secure payment gateways."
        img1={ecd4}
        img2={ecd3}
        img3={ecd2}
        img4={ecd1}
        alt1=" E-commerce Website at affordable price"
        alt2="Platform-Based E-commerce Development at affordable price "
        alt3="Mobile Commerce Development at affordable price"
        alt4=" Shopping Cart Development at affordable price"
     
      />
      <ProcessDevelop
        heading="Our Process For Ecommerce Development"
        para="ECommerce software development involves creating applications that enable online stores to manage their business."
        name1="Planning and Strategy"
        name2="Design and Prototyping"
        name3="Testing and Quality Assurance"
        name4="Maintenance and Support"
        name5="SEO and Digital Marketing"
      />
      <WhyChooseUs />
      <YourProject />
    </div>
  );
}

export default EcommerceDevelopment;
