import React from 'react';
import './Technical.css';
import team1 from '../../assets/team1.png'
import team2 from '../../assets/team2.png'
import team3 from '../../assets/team3.png'
import team4 from '../../assets/team4.png'
import team5 from '../../assets/team5.png'
import team6 from '../../assets/team6.png'

function WeareTeam() {
  return (
    <div className='container-fluid bg-black'>
        <div className='container pt-100 pb-100'>
        <h3 className='wertem mb-40'>We’re a team you’d enjoy spending time with
        (except for boring team meetings).</h3>
            <div className='grid-container'>
                <div className='team1'><img src={team1} alt='' className='w-100 img-fluid '/></div>
                <div className='team2'> <img src={team2} alt='' className=' w-100 img-fluid '/></div>
                <div className='team3'> <img src={team3} alt='' className='w-100 img-fluid'/></div>
                <div className='team4'><img src={team4} alt='' className='img-fluid w-100'/></div>
                <div className='team5'><img src={team5} alt='' className=' img-fluid w-100'/></div>
                <div className='team6'> <img src={team6} alt='' className=' img-fluid w-100'/></div>
            </div>  
        </div>
    </div>
  )
}

export default WeareTeam

