import React, { useState } from "react";
import employe1 from "../../assets/1p.png";
import employe2 from "../../assets/2p.png";
// import employe3 from "../../assets/3p.png";
import employe4 from "../../assets/4p.png";
import employe5 from "../../assets/5p.png";
import employe6 from "../../assets/6p.png";
import employe8 from "../../assets/8p.png";
import employe9 from "../../assets/9p.png";
import employe10 from "../../assets/10p.png";
import employe11 from "../../assets/11p.png";
import employe12 from "../../assets/12p.png";
import employe13 from "../../assets/13p.png";
import employe14 from "../../assets/14p.png";
import employe15 from "../../assets/15p.png";
import employe16 from "../../assets/16p.png";
import employe17 from "../../assets/17p.png";
import employe18 from "../../assets/18p.png";
import employe19 from "../../assets/19p.png";
import employe20 from "../../assets/20p.png";
import employe21 from "../../assets/21p.png";
import employe22 from "../../assets/22p.png";
import bitmoji11 from  "../../assets/11pm.png"
import bitmoji1 from  "../../assets/1pm.png"
import bitmoji6 from "../../assets/6pm.png"
import bitmoji8 from "../../assets/8pm.png"
import bitmoji21 from "../../assets/21pm.png"
import bitmoji14 from "../../assets/14pm.png";
import bitmoji13 from "../../assets/13pm.png";
import bitmoji20 from "../../assets/20pm.png";
import bitmoji16 from "../../assets/16pm.png";
import bitmoji12 from "../../assets/12pm.png";
import bitmoji17 from "../../assets/17pm.png";
import bitmoji10 from "../../assets/10pm.png";
import bitmoji18 from "../../assets/18pm.png";
import bitmoji4 from "../../assets/4pm.png";
import bitmoji2 from "../../assets/2pm.png";
import bitmoji15 from "../../assets/15pm.png";
import bitmoji22 from "../../assets/22pm.png";
import bitmoji9 from "../../assets/9pm.png";
import bitmoji19 from "../../assets/19pm.png";
import bitmoji5 from "../../assets/5pm.png";



import "./AboutScroll.css";

function PeoplePhoto({ X, Y, id, className ,alt}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`people-photo-container ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id={id}
    >
      <img src={isHovered ? Y : X} alt={alt} className="rect-imgs img-fluid" />
    </div>
  );
}

function PeopleRirabh() {
  return (
    <div className="container mt-100">
      <div className="peopbehri align-self-center text-center d-lg-none mb-40">
          <span className="whoareu">People behind</span>
          <br />
          <span className="whoareu">Rirabh</span>
        </div>
      <div className="employe-container">
        <PeoplePhoto X={bitmoji1} Y={employe1} className="employe1" alt="ajay rirabh" />
        <PeoplePhoto X={bitmoji2} Y={employe2} className="employe2" alt="alka rirabh" />
        <PeoplePhoto X={bitmoji4} Y={employe4} className="employe3" alt="bharti rirabh"/>
        <PeoplePhoto X={bitmoji4} Y={employe4} className="employe14" alt="rirabh"/>
        <PeoplePhoto X={bitmoji5} Y={employe5} className="employe5" alt="chaaru rirabh"/>
        <PeoplePhoto X={bitmoji6} Y={employe6} className="employe6" alt="deepak rirabh" />
        <PeoplePhoto X={bitmoji11} Y={employe11} className="employe7" alt="himanshu ratnakar rirabh" />
        <PeoplePhoto X={bitmoji8} Y={employe8} className="employe8" alt="deepali rirabh"/>
        <PeoplePhoto X={bitmoji9} Y={employe9} className="employe18"  alt="harjinder rirabh"/>
        <PeoplePhoto X={bitmoji10} Y={employe10} className="employe10" alt="hement rirabh" />
        <PeoplePhoto X={bitmoji22} Y={employe22} className="employe11" alt="vanshika rirabh"/>
        <div className="peopbehri align-self-center text-center hide-on-small">
          <span className="whoareu">People behind</span>
          <br />
          <span className="whoareu">Rirabh</span>
        </div>
        <PeoplePhoto X={bitmoji12} Y={employe12} className="employe12" alt="manshi rirabh"/>
        
        <PeoplePhoto X={bitmoji13} Y={employe13} className="employe13"  alt="nitish rirabh"/>      
        {/* NITISH */}
        <PeoplePhoto X={bitmoji14} Y={employe14} className="employe4" alt="payal rirabh"/>
        <PeoplePhoto X={bitmoji15} Y={employe15} className="employe15" alt="rajesh rirabh"/>
        <PeoplePhoto X={bitmoji16} Y={employe16} className="employe16" alt="roopa rirabh"/>
        <PeoplePhoto X={bitmoji17} Y={employe17} className="employe17" alt="rupali rirabh" />
        <PeoplePhoto X={bitmoji18} Y={employe18} className="employe9" alt="sachin rirabh" />
        <PeoplePhoto X={bitmoji19} Y={employe19} className="employe19" alt="shashank rirabh" />
        <PeoplePhoto X={bitmoji20} Y={employe20} className="employe20 " alt="sowmya rirabh" />
        <PeoplePhoto X={bitmoji21} Y={employe21} className="employe21 " alt="umang rirabh"/>
        <PeoplePhoto X={bitmoji11} Y={employe11} className="employe22 " alt="himanshu ratnakar rirabh" />
      </div>
    </div>
  );
}

export default PeopleRirabh;
