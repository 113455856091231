import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routing from './route/index';
import NavbarCom from './component/Navbar/NavbarCom';
import Footer from './component/Footer/Footer';
import { FaLongArrowAltUp } from "react-icons/fa";

function App() {
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <BrowserRouter>
      <>
        <NavbarCom />
        <Routing />
        <Footer />
        <button 
          className="go-to-top" 
          onClick={scrollToTop} 
          >
          <FaLongArrowAltUp/>
        </button>
      </>
    </BrowserRouter>
  );
}

export default App;
