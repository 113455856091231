import React, { Fragment } from "react";
// import founder from "../../assets/founder.png";
import callerspot from "../../assets/callerspot.png";
import "./WorkCard.css";
import "../../App.css";
import mitusbhi from "../../assets/mitushbhi.png";
import seven from "../../assets/Sevenshadestech.png";
// import frame1 from "../../assets/Frame 66.png";

function WorkCard() {
  return (
    <Fragment>
    <div className="container somr">
      <div className="d-flex justify-content-center flex-column align-items-center ideationu">
        <div className="text-center ">
          <h1 id="ourwork">Our Work</h1>
          <p className="text-center" id="workwedefine">
            We define business processes through ideation, creation, and
            optimization with a team of exceptional strategists.
          </p>
        </div>
      </div>
      {/* card */}
      <div
        className="container d-flex flex-column flex-lg-row mt-4 containers"
        id="containercallerspot" >
        <div class="top-left hide-on-small"></div>
        <div className="col-lg-6 col-sm-12 pt-5 ps-lg-5 ">
          <p id="workcallerspot">CALLERSPOT</p>
          <p id="workbuilding1">
          Your brand, perfectly crafted. Every time.
          </p>
          <div id="workisalwayspretty">
            <div className="mt-40 mb-40 ms-lg-3  p-4">
              <p id="workispretty">
                "My sincere gratitude for the exceptional service provided by the Rirabh team. Our recent experience with Callerspot has been nothing short of outstanding."
              </p>
              <div className="d-flex align-items-center mt-3">
                <div>
                  {/* <img src={founder} alt="Founder" className="img-fluid" /> */}
                </div>
                <div className="ms-3">
                  {/* <span id="worknamesurname">Name Surname</span>
                  <br/>
                  <span id="workfounder">Founderr</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 pt-5 d-flex justify-content-center align-items-center">
          <img src={callerspot} className="img-fluid" alt="Callerspot" />
        </div>
        <div className="workbrighttom hide-on-small"></div>
      </div>
      {/*card 2 */}
      <div
        className="container d-flex flex-column flex-lg-row containerss"
        id="containercallerspot1" >
        <div className="col-lg-6 col-sm-12 pt-5 ps-lg-5 ">
          <p id="workcallerspot">MITSUBISHI ELECTIRIC</p>
          <p id="workbuilding1">
         Commitment to delivering the highest quality of service
          </p>
          <div id="workisalwayspretty1">
            <div className="mt-40 mb-40 ms-lg-3 ms-0 p-4">
              <p id="workispretty">
                "Our experience with your team was outstanding. Your professionalism and dedication were evident in every aspect of our engagement."
              </p>
              <div className="d-flex align-items-center mt-3">
                <div>
                  {/* <img src={founder} alt="Founder" className="img-fluid" /> */}
                </div>
                <div className="ms-3">
                  {/* <span id="worknamesurname">Name Surname</span>
                  <br/>
                  <span id="workfounder">Founder</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 d-flex justify-content-end ">
          <img src={mitusbhi} className="img-fluid" alt="Callerspot"   style={{ marginRight: "-12px"}}/>
        </div>
        <div className=" hide-on-small bottomrightss"></div>
      </div>
      {/* card3 */}
      <div className="container d-flex flex-column flex-lg-row  containers"
        id="containercallerspot2">
        <div className="col-lg-6 col-sm-12 pt-5 ps-lg-5 ">
          <p id="workcallerspot">SEVEN SHADES TECH</p>
          <p id="workbuilding">
        Designed to meet client needs and exceed client expectations
          </p>
          <div id="workisalwayspretty1">
            <div className="mt-40 mb-40 ms-lg-3 ms-0 p-4">
              <p id="workispretty">
                "I am extremely pleased with the outcome of our website project. The Rirabh team did a fantastic job from start to finish."
              </p>
              <div className="d-flex align-items-center mt-3">
                <div>
                  {/* <img src={founder} alt="Founder" className="img-fluid" /> */}
                </div>
                <div className="ms-3">
                  {/* <span id="worknamesurname">Name Surname</span>
                  <br/>
                  <span id="workfounder">Founder</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12  d-flex justify-content-end">
          <img
            src={seven}
            className="img-fluid "
            alt="Callerspot"
              style={{ marginRight: "-12px"}}
            />
        </div>
        <div className="hide-on-small rightbottoms"></div>
      </div>
      <div className="d-flex justify-content-center " id="workbuttonmb">
      <a href="/our-work">
      <button className="workbutton" >View All</button>
      </a>
      </div>
      </div>
    </Fragment>
  );
}

export default WorkCard;
