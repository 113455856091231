import React from "react";
import "./Footer.css";
import logo from "../../assets/NAVLOGO.svg";
import linkedin from "../../assets/linkedin.svg";
import insta from "../../assets/insta.svg";
import twitter from "../../assets/twitter.svg";
import whatsapp from "../../assets/whatsapp.svg";
import facebook from "../../assets/facebook.svg";
import call from "../../assets/call.svg";
import email from "../../assets/email.svg";
import location from "../../assets/location.svg";

const Footer = () => {
  const handleCallClick = () => {
    window.location.href = 'tel:0120-605-0220';
  };
const handleMailClick =()=>{
  window.location.href='mailto:info@rirabh.com'
}
const handleLocation=()=>{

}
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <img src={logo} alt="Company Logo" className="my-5" />
            <p>
              Rirabh is one platform where you get assorted IT products and
              services for diverse needs of your business. We serve all types of
              businesses-small or large and accelerate their growth with our
              latest technology.
            </p>
            <span className="footerfollow">Follow us</span>
            <div className="d-flex">
              <a
                href="https://www.facebook.com/RirabhConsultingServicesLLP/?show_switched_toast"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  className="mx-2 img-fluid my-3 facebook"
                />
              </a>
              <a
                href="https://www.twitter.com/rirabh"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitter}
                  alt="Twitter"
                  className="mx-2 img-fluid my-3 twitter"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/rirabh-consulting-services-llp/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedin}
                  alt="LinkedIn"
                  className="mx-2 img-fluid my-3 linkedin"
                />
              </a>
              <a
                href="https://www.instagram.com/rirabh_2020/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={insta}
                  alt="Instagram"
                  className="mx-2 img-fluid my-3 instagram"
                />
              </a>
              <img
                src={whatsapp}
                alt="WhatsApp"
                className="mx-2 img-fluid my-3 whatsapp"
              />
            </div>
            <div className="hide-on-small">
              <hr />
              <p>Copyright © 2021 All Rights Reserved.</p>
            </div>
          </div>
          <div className="col-lg-5 col-sm-12">
            <div className="row">
              <div className="col-5">
                <h5 className="my-5 technologiesh5">Technologies</h5>
                <ul className="tech-list">
                  <li>Python</li>
                  <li>React JS</li>
                  <li>Node JS</li>
                  <li>React Native</li>
                  <li>Laravel</li>
                  <li>Shopify</li>
                  <li>WordPress</li>
                </ul>
              </div>
              <div className="col-7">
                <h5 className="my-5 technologiesh5">Services</h5>
                <ul className="tech-list">
                  <li>
                    <a href="/software-development">Custom Software Development</a>
                  </li>
                  <li>
                    <a href="/mobile-app-development">Mobile Application Development</a>
                  </li>
                  <li>
                    <a href="/web-development">Website Development</a>
                  </li>
                  <li>
                    <a href="/uiux-design">UI/UX Design</a>
                  </li>
                  <li>
                    <a href="/ecommerce-development">Ecommerce Development</a>
                  </li>
                  <li>Cloud and DevOps Solutions</li>
                  <li>Remote Resource Outsourcing</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12">
            <h5 className="my-5 technologiesh5">Contact</h5>
            <div className="d-flex my-3" onClick={handleCallClick}>
              <img src={call} alt="Call" className="img-fluid" />
              <span className="ms-2 contact1infoc">0120-605-0220</span>
            </div>
            <div className="d-flex my-3" onClick={handleMailClick} >
              <img src={email} alt="Email" />
              <span className="ms-2 contact1infoc">info@rirabh.com</span>
            </div>
            <div className="d-flex my-3" onClick={handleLocation}>
              <img src={location} alt="Location" />
              <span className="ms-2 contact1infoc">
                Suite 1403,1405 T-3, Nx-One BYTE, Techzone-4, Greater Noida West, Uttar Pradesh(201306)
              </span>
            </div>
            <div className="d-lg-none">
              <hr />
              <p>Copyright © 2021 All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
