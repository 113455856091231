import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import cb1 from "../../src/assets/cb4.svg";
import cb2 from "../../src/assets/cb3.svg";
import cb3 from "../../src/assets/cb2.svg";
import cb4 from "../../src/assets/cb1.svg";
import { Helmet } from "react-helmet";

function CallBroadcasting() {
  return (
    <div>
      <Helmet>
        <title>Voice Call Broadcasting Solutions in India | Rirabh</title>
        <meta
          name="description"
          content="Explore top Voice Call Broadcasting solutions in India, delivering scalable and effective communication tools for businesses to reach wider audiences instantly."
        />
        <meta
          name="keywords"
          content="Voice call broadcasting solutions, outbound dialer software, auto dialer software, call center solutions India, best outbound dialer provider, voice broadcasting services, scalable communication tools, call broadcasting India, business communication solutions"
        />
      </Helmet>

      <CustomSDev
        heading="Call Broadcasting"
        para="Achieve unparalleled delivery rates globally through our cutting-edge."
      />
      <CusSoftDevelopment
        MainHeading="WE OFFER"
        HPara="Customize calls through voice solutions. "
        h1="Voice Broadcast: "
        p1="Reach your audience with personalized voice broadcast messages. "
        h2="Voice Business:"
        p2="Personalized and efficient communication channels for enterprises.   "
        h3="Audio Bridge : "
        p3=" join a single phone call simultaneously from different locations. "
        h4="Global SIP  : "
        p4="International business calls, or delivering personalized messages, and connections effortlessly. "
        img1={cb1}
        img2={cb2}
        img3={cb3}
        img4={cb4}
        alt1=" Voice Broadcast"
        alt2="Voice Business"
        alt3="Audio Bridge"
        alt4="Global SIP"
      />
      <ProcessDevelop
        heading="Call Broadcasting Implementation Process"
        para="Call Broadcasting Solution involves several key steps Such as:"
        name1="Planning & Preparation"
        name2="System Setup"
        name3="Execution"
        name4="Response Handling"
        name5="Reporting & Analysis"
      />
      <WhyChooseUs />
    </div>
  );
}

export default CallBroadcasting;
