import React, { useState, useRef } from "react";
import "./YourProject.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Projectresp from "./Projectresp";
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function YourProject() {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const formRef = useRef(null);
  const phoneInputRef = useRef(null);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = Object.fromEntries(data.entries());

    if (!validateEmail(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      setLoading(true);

      const response = await fetch('https://webcrmapi.nayeedisha.in/api/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to add Sent Message');
      }

      setLoading(false);
      setSuccessMessage(responseData.message);
      setErrorMessage(null);
      formRef.current.reset();
      if (phoneInputRef.current) {
        phoneInputRef.current.clear();
      }

      Swal.fire({
        title: "Success!",
        text: responseData.message,
        icon: "success",
      });

      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      console.error("Error:", error); // Log the full error object for debugging

      let errorMsg = 'An error occurred';
      if (error.message) {
        errorMsg = error.message;
      } else if (error.response && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message;
      }

      setLoading(false);
      setSuccessMessage(null);
      setErrorMessage(errorMsg);

      Swal.fire({
        title: "Error!",
        text: errorMsg,
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <div className="d-lg-none mt-100 text-center">
        <span id="yourchata">Let's chat about your project</span>
        <br />
        <span id="yourconnect">CONNECT WITH US</span>
      </div>
      <div className="yourproject">
        <div className="container px-5 containerrelativetop hide-on-small">
          <div className="row">
            <div className="col-lg-6 col-md-5 col-12 chatagain">
              <span id="yourchata">Let's chat about your project</span>
              <br />
              <span id="yourconnect">CONNECT WITH US</span>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hide-on-small projectmargh">
              <Projectresp />
            </div>
            <div className="col-lg-6 col-12">
              <div className="formproject">
                <form onSubmit={submitForm} ref={formRef} noValidate>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control col-12 my-3 p-2 rounded"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control col-12 my-3 p-2 rounded"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-group">
                  <PhoneInput className=" rounded"
                        inputProps={{ name: 'phone_number', required: true, autoFocus: false }} country={'in'} inputRef={phoneInputRef} />
                 
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control col-12 my-3 p-4 rounded"
                      placeholder="Tell us about your requirement"
                      required
                    />
                  </div>
                  <div className="text-center">

                  <button type="submit" className="formButton " disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                  </div>
                </form>
                {successMessage && (
                  <div className="alert alert-success mt-3">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="alert alert-danger mt-3">
                    {errorMessage}
                  </div>
                )}
              </div>
              <div className="d-lg-none">
                <Projectresp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default YourProject;
