import React from "react";
import "./videooverlay.css";
import doublearrow from "../../assets/double-arrow.svg";

function Buttonvideo({ id, classname ,btnid}) {
  return (
    <button className="align-items-center hide-at-310" id={id}>
    <div className="d-flex" id={btnid}>
      <span className={classname}><a href="/contact-us" style={{textDecoration:"none", color:"black"}}>Consult With Our Expert For Free </a></span>{" "}
      <img src={doublearrow} alt="" />
      </div>
    </button>
  );
}

export default Buttonvideo;
