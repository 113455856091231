import React from "react";
import "./cusSoftDevelopment.css";
import CommCard from "../Parts/CommCard";

function CustomOffer({heading,para}){
  return(
    <>
 <div className="d-flex justify-content-center">
          <div className="">
            <h2 className=" cursoft">
{heading}
            </h2>
            <p className="tailoredsol">
              {para}
            </p>
          </div>
        </div>
    </>
  )
}

function CusSoftDevelopment({MainHeading,HPara,h1,p1,h2,p2,h3,p3,h4,p4,img1,img2,img3,img4,alt1,alt2,alt3,alt4}) {
  return (
    <div>
     
      <div className="container customtitle ">
       <CustomOffer
        heading={MainHeading}
        para={HPara}
       />
        <CommCard
          heading1={h1}
          para1={p1}
          color1="#0C4F38"
          heading2={h2}
          para2={p2}
          color2="#4F400D"
            img1={img1}
            img2={img2}
            alt1={alt1}
            alt2={alt2}
          />
          <CommCard
            heading1={h3}
            para1={p3}
            color1="#1E1662"
            heading2={h4}
            para2={p4}
            color2="#511343"
            img1={img3}
            img2={img4}
            alt1={alt3}
            alt2={alt4}
          />
      </div>
  
    </div>
  );
}

export default CusSoftDevelopment;


