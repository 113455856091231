import React from "react";
import CustomSDev from "../component/Parts/CustomSDev";
import Aboutscroll from "../component/AboutUs/AboutScroll";
import Hidebutton from "../component/Career/Hidebutton";
import group from "../assets/Group2039.svg";
import OurMission from "../component/AboutUs/OurMission";
// import MeetOurTeam from "../component/AboutUs/MeetOurTeam";
import PeopleRirabh from "../component/AboutUs/PeopleRirabh";
import Testimonial from "../component/AboutUs/Testimonial";
import YourProject from '../component/YourProject/YourProject'
import { Helmet } from "react-helmet";
function Aboutus() {
  return (
    <div>
     
      <Helmet>
  <title>About Us: Providing Cost-Effective IT Solutions for Businesses | Rirabh</title>
  <meta
    name="description"
    content="Learn about Rirabh, a leading provider of cost-effective IT solutions tailored to meet the needs of various businesses across different industries."
  />
  <meta
    name="keywords"
    content="About Rirabh, IT solutions provider, cost-effective IT services, business IT solutions, IT consulting, tailored IT services, IT solutions company India, technology solutions provider, custom IT solutions, IT services for businesses"
  />
</Helmet>

  
      <CustomSDev
        heading="Behind the Scenes "
        para="Discover the passionate team, the innovative processes, and the relentless dedication that drive our success. "
      />
      <Hidebutton
        heading="Who is Rirabh?  "
        para="Rirabh is one of the leading web solution companies specializing in providing businesses worldwide with custom technology solutions. We have a qualified, experienced, and dedicated developers team that is well-capable to transforming your business with emerging and latest running technologies. "
        image={group}
      />
      <OurMission />
      <Aboutscroll />
      {/* <MeetOurTeam/> */}
      <PeopleRirabh/>
      <Testimonial/>
      <YourProject/>
    </div>
  );
}

export default Aboutus;
