import React from 'react'
import dropdownItems from './Datadrop'
import { useState } from 'react';
import './Listitem.css'


const PlusIcon = ({ className }) => (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24">
      <path
        fill="none"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        d="M12 5v14M5 12h14"
      />
    </svg>
  );
  
function Listitemsm() {

    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
      setOpenDropdown(openDropdown === index ? null : index);
    };

  return (
    <div >
      {dropdownItems.map((item, index) => (
        <div key={index} >
        <div className='d-flex justify-content-between my-2 ' onClick={() => toggleDropdown(index)}>
          <span
            className="text-black w-100 position-relative"
            >
            {item.label}
          </span>
            <PlusIcon className={`Plus-icon position-absolute me-3 end-0 ${openDropdown === index ? 'rotate220' : ''}`} />
          </div>
         
          <ul className={` mt-2 ${openDropdown === index ? 'd-block' : 'd-none'}`} style={{ listStyleType: 'none'}}>
            {item.links.map((link, linkIndex) => (
              <li className='my-3' key={linkIndex} style={{margin:"-15px"}}><a className="linkname " href={link.url}>{link.text}</a></li>
            ))}
          </ul>
          <hr/>
        </div>
      ))}
     
    </div>
  )
}

export default Listitemsm