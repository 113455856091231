import React from "react";
import group from "../../assets/Frame139.svg";
import Hidebutton from "./Hidebutton";

function WhyTeam() {
  return (
<Hidebutton para="   Motivated, driven and fun are the words that best describe the work
            atmosphere at MobileCoderz in action and in spirit. If you relate
            yourself to it, surely we have a profile waiting for your name tag."
              heading="
Why Join Our Team"
image={group}
            />
  );
}

export default WhyTeam;
