import React from 'react';

function Service() {
  return (
    <div className="container">
      <h1 className="mt-5">Service Page</h1>
    </div>
  );
}

export default Service;
