import React, { useState } from "react";
import gallery1 from "../../assets/Gallery.jpg";
import gallery2 from "../../assets/Gallery-1.jpg";
import gallery3 from "../../assets/Gallery-2.jpg";
import gallery4 from "../../assets/Gallery-3.jpg";
import gallery5 from "../../assets/Gallery-4.jpg";
import gallery6 from "../../assets/Gallery-5.jpg";
import gallery7 from "../../assets/Gallery-6.jpg";
import gallery8 from "../../assets/Gallery-7.jpg";
import gallery9 from "../../assets/Gallery-8.jpg";
import gallery10 from "../../assets/Gallery-9.jpg";
import gallery11 from "../../assets/Gallery-10.jpg";
import gallery12 from "../../assets/Gallery-11.jpg";
import gallery13 from "../../assets/Gallery-12.jpg";
import gallery14 from "../../assets/Gallery-13.jpg";
import gallery15 from "../../assets/Gallery-14.jpg";
import gallery16 from "../../assets/Gallery-15.jpg";
import gallery1c from "../../assets/Galleryc.jpg";
import gallery2c from "../../assets/Gallery-1c.jpg";
import gallery3c from "../../assets/Gallery-2c.jpg";
import gallery4c from "../../assets/Gallery-3c.jpg";
import gallery5c from "../../assets/Gallery-4c.jpg";
import gallery6c from "../../assets/Gallery-5c.jpg";
import gallery7c from "../../assets/Gallery-6c.jpg";
import gallery8c from "../../assets/Gallery-7c.jpg";
import gallery9c from "../../assets/Gallery-8c.jpg";
import gallery10c from "../../assets/Gallery-9c.jpg";
import gallery11c from "../../assets/Gallery-10c.jpg";
import gallery12c from "../../assets/Gallery-11c.jpg";
import gallery13c from "../../assets/Gallery-12c.jpg";
import gallery14c from "../../assets/Gallery-13c.jpg";
import gallery15c from "../../assets/Gallery-14c.jpg";
import gallery16c from "../../assets/Gallery-15c.jpg";
import { MdNavigateNext , MdNavigateBefore} from "react-icons/md";
import "./GalleryDesign.css";

const galleryImages = [
  { image: gallery1, imageC: gallery1c },
  { image: gallery2, imageC: gallery2c },
  { image: gallery3, imageC: gallery3c },
  { image: gallery4, imageC: gallery4c },
  { image: gallery5, imageC: gallery5c },
  { image: gallery6, imageC: gallery6c },
  { image: gallery7, imageC: gallery7c },
  { image: gallery8, imageC: gallery8c },
  { image: gallery9, imageC: gallery9c },
  { image: gallery10, imageC: gallery10c },
  { image: gallery11, imageC: gallery11c },
  { image: gallery12, imageC: gallery12c },
  { image: gallery13, imageC: gallery13c },
  { image: gallery14, imageC: gallery14c },
  { image: gallery15, imageC: gallery15c },
  { image: gallery16, imageC: gallery16c }
];

function GalleryDesign() {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleOpenLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleCloseLightbox = () => {
    setIsOpen(false);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length);
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    setPhotoIndex((photoIndex + 1) % galleryImages.length);
  };

  return (
    <div className="container mt-minus60">
      <h1 className="mb-40 text-center linebusiness">
        Explore Our Visual Journey!
      </h1>
      <p className="businessFor mb-40 text-center">
        Step into our world through a curated collection of images that tell the
        story of who we are and what we do. Each photograph captures a moment, a
        project, or an experience that defines our journey.
      </p>
      <div className="container container-gallery-design">
        {galleryImages.map((item, index) => (
          <div
            key={index}
            className={`gl-${index + 1}`}
            onClick={() => handleOpenLightbox(index)}
          >
            <img
              src={item.image}  
              className="img-fluid w-100"
              alt={`Gallery item ${index + 1}`}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={handleCloseLightbox}
        >
          <button
            onClick={handlePrevImage}
            style={{
              position: "absolute",
              top: "50%",
              left: "10px",
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              fontSize: "3rem",
              cursor: "pointer",
              transform: "translateY(-50%)",
            }}
          >
            <MdNavigateBefore/>
          </button>
          <img
            src={galleryImages[photoIndex].imageC} 
            style={{
              maxWidth: "90%",
              maxHeight: "80%",
              objectFit: "contain",
            }}
            alt="rirabh employe"
          />
          <button
            onClick={handleNextImage}
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              fontSize: "3rem",
              cursor: "pointer",
              transform: "translateY(-50%)",
            }}
          >
            <MdNavigateNext />
          </button>
        </div>
      )}
    </div>
  );
}

export default GalleryDesign;
