import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import YourProject from "../component/YourProject/YourProject";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import ma1 from "../../src/assets/ma1.svg";
import ma2 from "../../src/assets/ma2.svg";
import ma3 from "../../src/assets/ma3.svg";
import ma4 from "../../src/assets/ma4.svg";
import { Helmet } from "react-helmet";

function MobileAppDevelopment() {
  return (
    <div>
         <Helmet>
    <title>Mobile App Development Services | Rirabh</title>
        <meta
          name="description"
          content="Our mobile app development services offer custom solutions to create innovative, user-friendly apps for iOS and Android. 
          Boost your business with our expert team!"
        />
        <meta name="keywords" content="mobile app development company, mobile app development services, mobile app development service prrovider, mobile app development services in India" />
      </Helmet>
      <CustomSDev
        heading="Mobile App Development"
        para="Give seamless user experiences across all modern platforms and devices through our mobile app services."
      />
      <CusSoftDevelopment
        MainHeading="WE OFFER "
        HPara="Empower your business to soar beyond expectations via a customized mobile app facility  "
        h1="Android App Development: "
        p1="Choosing a development approach is subjective and requires consideration.   "
        h2="iOS App Development: "
        p2="We turn ideas into reality and have created more than 200 iOS apps.  "
        h3=" Cross-platform Mobile App Development:"
        p3=" Not only provides top-class features but also saves time and cost of implementation for the customers. "
        h4="Latest Technology:"
        p4="Our latest trend & modern methodology to enhance user experience, performance, and functionality."
        img1={ma4}
        img2={ma3}
        img3={ma2}
        img4={ma1}
        alt1="Android App Development"
        alt2="iOS App Development"
        alt3="Cross-platform Mobile App Development"
        alt4="Latest Technology"
      />
      <ProcessDevelop
        heading="Our Process For Mobile App Development"
        para="Every stage is equally essential for building a strong, reliable application within delivery timelines."
        name1="Consultation and Strategy"
        name2="Design and Prototyping"
        name3="Testing and Quality Assurance"
        name4="Integration and Deployment"
        name5="Maintenance and Support"
      />
      <WhyChooseUs />
      <YourProject />
    </div>
  );
}

export default MobileAppDevelopment;
