import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../page/Home";
import Service from "../page/Service";
import SoftwareDevelopment from "../page/SoftwareDevelopment";
import Contactus from "../page/Contactus";
import Aboutus from "../page/Aboutus";
import Career from "../page/Career";
import WebDevelopment from "../page/WebDevelopment";
import OurWork from "../page/OurWork";
import Gallery from "../page/Gallery";
import MobileAppDevelopment from "../page/MobileAppDevelopment";
import UiUxDesign from "../page/UiUxDesign";
import JobApplicationForm from "../page/JobApplicationForm";
import CallCenterSolution from "../page/CallCenterSolution";
import CallCenterCRM from "../page/CallCenterCRM";
import VoIPBilling from "../page/VoIPBilling";
import SMSSellingPortal from "../page/SMSSellingPortal";
import PaymentonIVR from "../page/PaymentonIVR";
import OutboundDialer from "../page/OutboundDialer";
import CallBroadcasting from "../page/CallBroadcasting";
import APIIntregration from "../page/APIIntregration";
import EcommerceDevelopment from "../page/EcommerceDevelopment";
import Pagenotfound from "../page/PageNotFound";
const Routing = () => {
  return (
    <Routes>
      <Route path="/api-intregration" element={<APIIntregration />} />
      <Route path="/ecommerce-development" element={<EcommerceDevelopment/>} />
      <Route path="/call-broadcasting" element={<CallBroadcasting />} />
      <Route path="/outbound-dialer" element={<OutboundDialer />} />
      <Route path="/payment-on-ivr" element={<PaymentonIVR />} />
      <Route path="/sms-selling-portal" element={<SMSSellingPortal />} />
      <Route path="/voip-billing" element={<VoIPBilling />} />
      <Route path="/call-center-crm" element={<CallCenterCRM />} />
      <Route path="/call-center-solution" element={<CallCenterSolution />} />
      <Route path="/job-application-form" element={<JobApplicationForm />} />
      <Route path="/uiux-design" element={<UiUxDesign />} />
      <Route path="/mobile-app-development" element={<MobileAppDevelopment />} />
      <Route path="/career" element={<Career />} />
      <Route path="/about-us" element={<Aboutus />} />
      <Route path="/our-work" element={<OurWork />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/contact-us" element={<Contactus />} />
      <Route path="/web-development" element={<WebDevelopment />} />
      <Route path="/software-development" element={<SoftwareDevelopment />} />
      <Route path="/service" element={<Service />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Pagenotfound/>} />
    </Routes>
  );
};

export default Routing;
