const dropdownItems = [
  {
    label: "Services",
    links: [
      { text: "Software Development", url: "/software-development" },
      { text: "Web Development", url: "/web-development" },
      { text: "Mobile App Development", url: "/mobile-app-development" },
      { text: "UI/UX Design", url: "uiux-design" },
      { text: "Ecommerce Development", url: "ecommerce-development" }
    ],
  },
  {
    label: "Products",
    links: [
      { text: "Call Center Solution", url: "/call-center-solution" },
      { text: "Call Center CRM", url: "/call-center-crm" },
      { text: "VoIP Billing", url: "/voip-billing" },
      { text: "SMS Selling Portal", url: "/sms-selling-portal" },
      { text: "Payment on IVR", url: "/payment-on-ivr" },
      { text: "Outbound Dialer", url: "/outbound-dialer" },
      { text: "Call Broadcasting", url: "/call-broadcasting" },
      { text: "API Intregration", url: "/api-intregration" },
    ],
  },
  {
    label: "Portfolio ",
    links: [
      { text: "Gallery", url: "/gallery" },
      { text:"Our Work", url:"/our-work"}
    ],
  },
  {
    label: "Company",
    links: [
      { text: "About us", url: "/about-us" },
      { text: "Career", url: "/career" },
      { text: "Contact us", url: "/contact-us" },
    ],
  },
];
export default dropdownItems;
