import React from "react";
import Googleframe from "../component/ContacUs/GoogleFrame";
import WorkingModel from "../component/WorkingModel/WorkingModel";
import LetgetStart from "../component/SoftwareDevelopment/LetgetStart";
import CustomSDev from "../component/Parts/CustomSDev";
import FreeQuotes from "../component/ContacUs/FreeQuotes";
import YourProject from "../component/YourProject/YourProject";
import { Helmet } from "react-helmet";
function Contactus() {
  return (
    <div>
      <Helmet>
        <title>Contact Us | Rirabh - Get in Touch for IT Solutions</title>
        <meta
          name="description"
          content="Contact Rirabh to discuss your IT needs. We're here to provide tailored, cost-effective IT solutions for businesses. Reach out to us today."
        />
        <meta
          name="keywords"
          content="Contact Rirabh, IT solutions contact, business IT support, IT consulting contact, custom IT solutions, IT services inquiry, Rirabh customer support, IT solutions provider contact, IT services company India"
        />
      </Helmet>

      <CustomSDev
        heading="Get In Touch & Get Started"
        para="Please send us a message and we will get back to you"
      />
      <FreeQuotes />
      <Googleframe />
      <LetgetStart />
      <WorkingModel />
      <YourProject />
    </div>
  );
}

export default Contactus;
