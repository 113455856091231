import React from 'react';
import './Technology.css';
import { Fragment } from 'react';
import frontend from '../../assets/Frontend.svg';
import backend from '../../assets/Backend.svg';
import database from '../../assets/Database&Servers.svg';
import ecommerce from '../../assets/EcommerceblckCMS.svg';
import mobileapp from '../../assets/MobileblckApp.svg';
import projectblck from '../../assets/ProjectblckManagement.svg';
import uxblck from '../../assets/UXblckDesign.svg';

function Technology() {
  return (
    <Fragment>
      <div className='backgroundTechnology'>
        <p className='contenttechno'>Technologies We Work With</p>
        <div className='container'>
          <div className='row rpwe'>
            <div className='col-lg-4 collg4  col-sm-12'>
              <img src={frontend} className='img-fluid img-fluidd ' alt='Frontend' />
            </div>
            <div className='col-lg-4 collg4  col-sm-12 margintoppp'>
              <img src={backend} className='img-fluid img-fluidd ' alt='Backend' />
            </div>
            <div className='col-lg-4 collg4  col-sm-12 margintoppp'>
              <img src={database} className='img-fluid img-fluidd ' alt='Database & Servers' />
            </div>
          </div>
          <div className='row rpwee '>
            <div className='col-lg-3 collg3 col6 col-6'>
              <img src={ecommerce} className='img-fluid img-fluidd ' alt='E-commerce & CMS' />
            </div>
            <div className='col-lg-3 collg3 col6 col-6'>
              <img src={mobileapp} className='img-fluid img-fluidd ' alt='Mobile App' />
            </div>
            <div className='col-lg-3 collg3 col6  col-6 margintoppp'>
              <img src={projectblck} className='img-fluid img-fluidd ' alt='Project Management' />
            </div>
            <div className='col-lg-3 collg3 col6   col-6 margintoppp'>
              <img src={uxblck} className='img-fluid img-fluidd ' alt='UX Design' />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Technology;
