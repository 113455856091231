import React from 'react';
import './WorkingModel.css';
import fixbidmodel from '../../assets/Fix-BidModel.svg';
import productconsultant from '../../assets/HourlyModel.svg';
import productoptimization from '../../assets/MonthlyRetentionModel.svg';

export default function WorkingModel() {
  return (
    <div className='container mainmarghu'>
      <p className='workingmodel'>Our Working Model</p>
      <p className='workingbusinessidea mt-40'>We define business processes through ideation, creation, and optimization with a team of exceptional strategists.</p>
      <div className='wm-grid-container'>
        <div className='wm-grid-item'>
          <div className='wm-card' >
            <img src={fixbidmodel} alt='' className='img-fluid'/>
            <p className='fixproduct my-3'>Fix- Bid Model</p>
            <p className='formonthlybased'>For each project we offer a fix cost by analyzing the scope of work and technology stack</p>
          </div>
        </div>
        <div className='wm-grid-item'>
          <div className='wm-card' id='productcons'>
            <img src={productconsultant} alt=''/>
            <p className='fixproduct my-3'>Product Consultation</p>
            <p className='formonthlybased'>Based on client's requirement, an hourly model for each technology stack is finalized</p>
          </div>
        </div>
        <div className='wm-grid-item'>
          <div className='wm-card' id='productoptim'>
            <img src={productoptimization} alt=''/>
            <p className='fixproduct my-3'>Product Optimization</p>
            <p className='formonthlybased'>Monthly cost will be charged from client based of resource skill set</p>
          </div>
        </div>
      </div>
    </div>
  );
}
