import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import ccs1 from "../../src/assets/ccs1.svg";
import ccs2 from "../../src/assets/ccs2.svg";
import ccs3 from "../../src/assets/ccs3.svg";
import ccs4 from "../../src/assets/ccs4.svg";
import { Helmet } from "react-helmet";

function CallCenterSolution() {
  return (
    <div>
         <Helmet>
        <title>
        Best Call Center Solution Providers in India | Rirabh
        </title>
        <meta
          name="description"
          content="Find the best call center solution providers in India offering advanced technology, reliable support, and tailored services to enhance your customer communication."/>
        <meta name="keywords" content="Affordable call centre solution, call centre solution providers in India, Top call centre solution providers in india" />
      </Helmet>
    <CustomSDev
      heading="Call Center Solution"
       para="Improve customer experience with a remote & feature-rich call center solution."
    />
      <CusSoftDevelopment MainHeading="WE OFFER"
        HPara="Amplifying Customer Loyalties Through Rirabh Call Centre Solutions "
        h1="Interactive Voice Response (IVR): "
        p1="Allows callers to interact with a computerized system through voice or keypad inputs. "
        h2="Call Recording and Monitoring: "
        p2="Captures calls for quality assurance, training, and compliance purposes."
        h3="Omni-Channel Support: "
        p3="Integrates various communication channels such as phone, email, chat, social media, and more. "
        h4="Analytics and Reporting:  "
        p4="Provides insights into call center performance, and other things to help optimize operations. "
        img1={ccs4}
        img2={ccs3}
        img3={ccs2}
        img4={ccs1}
        alt1="Interactive Voice Response"
        alt2="Call Recording and Monitoring"
        alt3="Omni Channel Support"
        alt4="Analytics and Reporting"
       
      />
      <ProcessDevelop
        heading="Call Center Solution Implementation Process"
        para="Implementing a call center solution involves several key steps Such as: "
        name1="Needs Assessment & Planning"
        name2="Development & Customization"
        name3="Testing"
        name4="Training & Documentation"
        name5="Deployment"
      />
      <WhyChooseUs/>
    </div>
  );
}

export default CallCenterSolution;
