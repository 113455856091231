import React from "react";
import "./HomeBusinessSol.css";
import Businesss from "./Businesss";

function HomeBusinessSol() {
  return (
    <div className="business-box pt-100 pb-100">
      <div className="container ">
        <div className="mainlinebusiness text-center  ">
          <h1 className="linebusiness">
            Tailored Solutions and Services We Provide For Different Industries:{" "}
          </h1>
          <p className="businessFor pt-40">
            Since 5 years, Rirabh has been delivering top-notch IT solutions
            globally. We wish to become your experienced partner, helping you to
            provide ultimate growth after our highly oriented technologies. .
          </p>
        </div>
        <Businesss />
      </div>
    </div>
  );
}

export default HomeBusinessSol;

//this page is develop by himanshu ratnakar
