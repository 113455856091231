import React from 'react'

function LetgetStart() {
  return (
    <div className='bgProcess-lets'>
    <h3 className='letusget'>
    Let's Get Started:
    </h3>
    <p className='readytobri mx-2 mt-4'>
    Ready to bring your software idea to life? Contact us today to discuss your project requirements, and let's collaborate to turn your vision into reality.
    </p>
    </div>
  )
}

export default LetgetStart