import React from "react";
// import navcall from "../../assets/NAVCALL.svg";
import "./Telephonecall.css";

function Telephonecall({clasname , id}) {
  return <a href="/contact-us" style={{textDecoration:"none"}}>
   <button className={clasname} id={id}>
      <span className="mx-3 " id="calltext">Contact us</span>
    </button>
  </a>
  
}

export default Telephonecall;
