import React from 'react'
import CustomSDev from '../component/Parts/CustomSDev'
import Technical from '../component/Career/Technical'
import WhyTeam from '../component/Career/WhyTeam'
import WeareTeam from '../component/Career/WeareTeam'
import LetgetStart from '../component/SoftwareDevelopment/LetgetStart'
import Testimonial from '../component/AboutUs/Testimonial'
import YourProject from '../component/YourProject/YourProject'
import { Helmet } from 'react-helmet'

export default function Career() {
  return (
    <div>
    <Helmet>
  <title>Careers at Rirabh | Join Our Innovative Team</title>
  <meta
    name="description"
    content="Explore exciting career opportunities at Rirabh. Join our innovative team and contribute to cutting-edge projects in a dynamic and supportive environment."
  />
  <meta
    name="keywords"
    content="Rirabh careers, job openings at Rirabh, work at Rirabh, Rirabh job opportunities, IT careers, Rirabh hiring, career growth, Rirabh employee benefits, join Rirabh, Rirabh job vacancies"
  />
</Helmet>

    <CustomSDev heading="Career" para="Join our team and be a part of an innovative, dynamic, and passionate community."/>
    <WhyTeam/>
       <Technical/>
       <WeareTeam/>
       <LetgetStart/>
       <Testimonial/>
       <YourProject/>
    </div>
  )
}
