import React, { useEffect } from 'react';
import rirabhbanner from '../../assets/rirabh banner.webm';
import './videooverlay.css';
import Buttonvideo from './Buttonvideo';
import bannermp4 from '../../assets/bannermp4.mp4';

const VideoOverlay = () => {
  useEffect(() => {
    const videoElement = document.querySelector('video');
    const playPromise = videoElement.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // Automatic playback started
        })
        .catch(error => {
          // Auto-play was prevented
          console.log('Autoplay prevented:', error);
          videoElement.muted = true; // Mute the video
          videoElement.play(); // Attempt to play again
        });
    }
  }, []);

  return (
    <div>
      <div className='d-lg-none col-12'>
        <div className='black-container'></div>
        <div className="content">
          <span id='smallmakingit'>UPGRADE YOUR POTENTIAL</span>
          <p className="my-2" id='smallupgrade'>We Formula The Best IT Solutions</p>
          <p className='my-4 mb-5' id='smallwelcometo'>
            Welcome to Rirabh- Want to establish a strong footprint for your brand so let's Empowering Tomorrow's Technology Today and use us as a powerful business tool.
          </p>
          <Buttonvideo id='smallvideobtn' classname='smallvideobtnspan' btnid='lasto' />
        </div>
      </div>
      <div className="video-container col-12">
        <video className="video" autoPlay loop muted playsInline>
          <source src={rirabhbanner} type="video/webm" />
          <source src={bannermp4} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay" id='hide-on-small'>
          <span className='my-3' id='videoworld'>UPGRADE YOUR POTENTIAL</span>
          <p className="mb-3" id='overlay-text'>We Formula The Best IT Solutions</p>
          <p className='mb-3' id='welcometag'>
            Welcome to Rirabh- Want to establish a strong footprint for your brand so let's Empowering Tomorrow's Technology Today and use us as a powerful business tool.
          </p>
          <Buttonvideo id='videobtn' classname='videobtnspan' />
        </div>
      </div>
    </div>
  );
};

export default VideoOverlay;
