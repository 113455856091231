import React from "react";
import casestudy1 from "../../assets/casemitusbhi.png";
import casestudy2 from "../../assets/casecaller.png";
import casestudy3 from "../../assets/caseuevya.png";
import casestudy4 from "../../assets/casenext.png";
import casestudy5 from "../../assets/caseringo.png";
import casestudy6 from "../../assets/caseseven.png";

import { FaArrowRight } from "react-icons/fa6";
import "./RecentWork.css";

const caseStudies = [
  {
    image: casestudy1,
    categories: ["Electrical Equipment", "B2B", "Technology"],
    title: "Case Study: Mitsubishi Electric",
    description:
      "Mitsubishi Electric is a leader in electrical and electronic equipment company collaborated with Rirabh...",
    link: "https://mitsubishielectric.in/",
  },
  {
    image: casestudy2,
    categories: ["Business Communication", "Cloud-based", "SaaS"],
    title: "Case Study: Caller Spot",
    description:
      "CallerSpot is a cloud-based business communication platform that offers...",
    link: "https://callerspot.com/",
  },
  {
    image: casestudy3,
    categories: ["Security", "Technology", "B2B"],
    title: "Case Study: Urvya Security",
    description:
      "Urvya Security is a security guard company using modern technology",
    link: "https://urvyasecurity.com/",
  },
  {
    image: casestudy4,
    categories: ["NGO", "Social Work", "Non-Profit"],
    title: "Case Study: Next Inning",
    description:
      "Next Inning is an Indian NGO that helps people in need.",
    link: "https://nextinning.in/",
  },
  {
    image: casestudy5,
    categories: ["Cloud Communication", "B2B", "Technology"],
    title: "Case Study: Ringopus",
    description:
      "Ringopus is an Indian cloud communication platform company collaborated with Rirabh...",
    link: "https://ringopus.com/",
  },
  {
    image: casestudy6,
    categories: ["Waste Management", "Environmental", "Technology"],
    title: "Case Study: Seven ShadesTech",
    description:
      "Seven Shades Tech is a waste management company collaborated with Rirabh...",
    link: "https://sevenshadestech.com/",
  },
];

function Rounded() {
  return <div className="roundedever" ></div>;
}

function RecentWork() {
  return (
    <div className="pb-100 recentworkingjo">
      <div className="container">
        <h6 className="text-center mb-40 ourrecentcse">
          Our Recent Case Studies
        </h6>
        <div className="row">
          {caseStudies.map((caseStudy, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12">
              <div className="case-study">
                <img
                  src={caseStudy.image}
                  alt="casestudy"
                  className="img-fluid w-100"
                />
                <p className="retailflutter mt-2 d-flex">
                  {caseStudy.categories.map((category, index) => (
                    <React.Fragment key={index}>
                      {category}
                      {index < caseStudy.categories.length - 1 && <Rounded />} 
                    </React.Fragment>
                  ))}
                </p>
                <h4 className="mt-3 casestuddd">{caseStudy.title}</h4>
                <p className="mt-3 retailndls">{caseStudy.description}</p>
                <br />
                <a href={caseStudy.link} target="_blank" rel="noreferrer" className="mt-3 retailflutterr">
                  Visit Now <FaArrowRight />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RecentWork;
