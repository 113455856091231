import React from 'react';

function Googleframe() {
  const apiKey = 'RIRABH_API_KEY';

  const mapUrl = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14011.869318216503!2d77.4325387!3d28.6007569!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef60445e16f5%3A0x173f943fdc437fcf!2sRirabh%20Consulting%20Services%20LLP!5e0!3m2!1sen!2sin!4v1724735888786!5m2!1sen!2sin=${apiKey}`;

  return (
    <div className='iframeg'>
      <iframe
        src={mapUrl}
        width="100%"
        height="600px"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Rirabh Maps Location"
      />
    </div>
  );
}

export default Googleframe;

// import React from 'react';

// function Googleframe() {
//   const apiKey = 'YOUR_API_KEY';

//   const mapUrl = `https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d14011.83870348027!2d77.4323112!3d28.6009865!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1722504354114!5m2!1sen!2sin=${apiKey}`;

//   return (
//     <div className='iframeg'>
//       <iframe
//         src={mapUrl}
//         width="100%"
//         height="600px"
//         style={{ border: 0 }}
//         allowFullScreen=""
//         loading="lazy"
//         referrerPolicy="no-referrer-when-downgrade"
//         title="Rirabh Maps Location"
//       />
//     </div>
//   );
// }

// export default Googleframe;
