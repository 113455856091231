import React from "react";
import "./contactus.css";
import ladiesm from "../../assets/Frame106.svg";
import twentyfour from "../../assets/24x7.svg";
import chat from "../../assets/chat1.svg";
import clipboard from "../../assets/clipboard.svg";

function Card({ imgSrc, altText, lines,className }) {
  return (
    <div className={className}>
      <div className="d-flex ">
        <div className="align-self-center">
          <img src={imgSrc} alt={altText} className=""  style={{height:"64px",width:"64px"}}/>
        </div>
        <div className="ms-5">
          {lines.map((line, index) => (
            <p key={index} className="busskycall mt-1">
              <b>{line.boldText}</b> {line.text}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

const cardData = [
  {
    imgSrc: chat,
    altText: "chat",
    className:"box-1",
    lines: [
      { boldText: "Business :", text: "info@rirabh.com" },
      { boldText: "Call :", text: "0120 605 0220" },
    ],
  },
  {
    imgSrc: twentyfour,
    altText: "24x7",
    className:"box-2",
    lines: [
      { boldText: "We’ll get back to you in 24 hours", text: "" },
      { boldText: "", text: "to address your needs as quickly as possible." },
    ],
  },
  {
    imgSrc: clipboard,
    altText: "clipboard",
    className:"box-1",
    lines: [
      { boldText: "We’ll prepare an estimation of the project", text: "" },
      { boldText: "", text: "describing the team composition, timeline, and costs." },
    ],
  },
];

function FreeQuotes() {
  return (
    <div className="container bg-white rounded-4 maindifgh">
      <div className="plg-40">
        <div className="text-center">
          <p className="typidisc">
            Let’s Discuss Your Requirements & Start Develop
          </p>
          <p className="typidisc">
            Something Unique.<b> Get a Free Quote! </b>
          </p>
        </div>
        <div className="gridbox mt-40">
          <div className="ladiessmf">
            <img className="img-fluid w-100" src={ladiesm} alt="ladiesm" />
          </div>
          {cardData.map((card, index) => (
            <Card
              key={index}
              imgSrc={card.imgSrc}
              altText={card.altText}
              lines={card.lines}
              className={card.className}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FreeQuotes;