import React, { useState } from 'react';
import BusSol from './BusSol';
import BusinessSolution from './BusinessSolution';
import BusinessCard from './BusinessCard';

function Businesss() {
  const [selectedCardId, setSelectedCardId] = useState(1);

  const handleCardSelect = (cardId) => {
    setSelectedCardId(cardId);
  };

  const selectedCard = BusSol.find(card => card.id === selectedCardId);


  return (
    <div>
      <BusinessSolution cards={BusSol} onSelect={handleCardSelect} />
      {selectedCard && <BusinessCard card={selectedCard} />}
    </div>
  );
}

export default Businesss;
