import React from "react";
import Slider from "react-slick";
import "./homehappy.css";
import frame4 from '../../assets/Frame 4.svg';
import frame5 from '../../assets/Frame 5.png';
import frame6 from '../../assets/Frame 6.png';
import frame7 from '../../assets/Frame 7.png';
import frame8 from '../../assets/Frame 8.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Homehappyclient() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      }
    ]
  };

  return (
    <div className="container my-5">
      <p id="happyclients" className="text-center mb-3">Discover Our Happy Clients</p>
      <Slider {...settings}>
        <div className="mt-2">
          <img src={frame4} className="img-fluid" alt="Client 1"/>
        </div>
        <div className="mt-2">
          <img src={frame5} className="img-fluid" alt="Client 2"/>
        </div>
        <div className="mt-2">
          <img src={frame6} className="img-fluid" alt="Client 3"/>
        </div>
        <div className="mt-2">
          <img src={frame7} className="img-fluid" alt="Client 4"/>
        </div>
        <div className="mt-2">
          <img src={frame8} className="img-fluid" alt="Client 5"/>
        </div>
      </Slider>
    </div>
  );
}

export default Homehappyclient;
