import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BusinessSolution.css";
import leftkey from "../../assets/left.svg";
import rightkey from "../../assets/right.svg";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow next" onClick={onClick} id="hide-on-small">
      <img src={rightkey} alt="Next" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow prev" onClick={onClick} id="hide-on-small" >
      <img src={leftkey} alt="Previous" />
    </div>
  );
};

function BusinessSolution({ cards, onSelect }) {
  const [selectedCard, setSelectedCard] = useState(1);

  const handleSelect = (id) => {
    setSelectedCard(id);
    onSelect(id);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1125,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots:true,
        },
      },
      
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="container my-5" id="borderfgh">
      <Slider {...settings}>
        {cards.map((card) => (
          <div
            key={card.id}
            className={`d-flex col-lg-2 riabhslickre ${selectedCard === card.id ? "selected" : ""}`}
            id="mainbusinessdiv"
            onClick={() => handleSelect(card.id)}
            style={selectedCard === card.id ? { borderBottom: "4px solid #FF7A30" } : {}}
          >
            <img
              src={card.icon}
              alt={card.name}
              className="img-fluid my-3"
              id="checkmark"
              style={{
                
                filter: selectedCard === card.id ? "invert(50%) sepia(100%) saturate(800%) hue-rotate(-10deg) brightness(100%) contrast(97%)" : "none"
              }}
            />
            <div className="align-self-center ms-1">
              <span id="bussol" style={selectedCard === card.id ? { color: "#FF7A30" } : {}}>
                {card.name}
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default BusinessSolution;
