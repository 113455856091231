import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import ccc1 from "../../src/assets/ccc1.svg";
import ccc2 from "../../src/assets/ccc2.svg";
import ccc3 from "../../src/assets/ccc3.svg";
import ccc4 from "../../src/assets/ccc4.svg";
import { Helmet } from "react-helmet";

export default function CallCenterCRM(){
    return(
        <>
  <Helmet>
        <title>
        Best CRM for Call Center | Rirabh
        </title>
        <meta
          name="description"
          content="Boost call center efficiency with our best crm for call center. Enhance customer interactions, streamline workflows, and improve agent productivity with tailored solutions."/>
        <meta name="keywords" content="best call center software in India, best call center software for small businesses, call center solution at affordable price" />
      </Helmet>
            <CustomSDev
      heading="Call Center CRM" para="CRM call center software has gained unstoppable growth, just check out the implementation process."
    />
        <CusSoftDevelopment MainHeading="WE OFFER"
        HPara="Rirabh offers the best CRM software for call centers that is a Fully integrated cloud CRM system. "
        h1="Keep in touch with your Customers:  "
        p1="As a call center, your main role is to always be available for your customers at all times.  "
        h2="Evaluate the Employee Performance: "
        p2="CRM can easily help you evaluate your employee’s efficiency."
        h3="Centralized Database:  "
        p3="Get everything in one place. "
        h4="Enhance the Customer Service:   "
        p4="CRM system is an essential tool for improving your overall customer experience. "
        img1={ccc4}
        img2={ccc3}
        img3={ccc2}
        img4={ccc1}
        alt1="touch with your Customers"
        alt2="Employee Performance"
        alt3="Centralized Database"
        alt4="Enhance the Customer Service"
     
      />
          <ProcessDevelop
        heading="Customer CRM Implementation Process"
        para="Call Center CRM solution involves several key steps Such as: "
        name1="Customer Data Collection"
        name2="Knowledge Management"
        name3="CRM Integration"
        name4="Monitoring and Reporting"
        name5="Customer Feedback"
      />
      <WhyChooseUs/>
        </>
    )
}