import React from 'react'
import fixbidmodel from '../../assets/Fix-BidModel.svg';
import productconsultant from '../../assets/HourlyModel.svg';
import productoptimization from '../../assets/MonthlyRetentionModel.svg';
import "./whychoose.css"

function WhyChooseUs() {
  return (
    <div className='container mainmarghu'>
    <div className='mb-100'>
<p className='question'>Why Choose Us</p>
<p className='wedefinebusiness mt-40 '>We define business processes through ideation, creation, and optimization with a team of exceptional strategists.</p>
<div className='container'>
<div className='row d-lg-flex justify-content-between'>
    <div className='col-lg-6 col-xxl-3  col-sm-12 ' >
    <div className='p-5' id='productoptim'>
        <img src={fixbidmodel} alt=''/>
        <p className='fixproduct my-3'>Experienced Team</p>
        <p className='formonthlybased'>Our team comprises seasoned developers, designers, and project managers with years of experience in software development.</p>
        </div>
    </div>
    <div className='col-lg-6 col-xxl-3  col-sm-12 ' id='exceptionalstr' >
    <div className='p-5' id='productcons'>
        <img src={productconsultant} alt=''/>
        <p className='fixproduct my-3'>Custom Solutions</p>
        <p className='formonthlybased'>We prioritize understanding your unique requirements and delivering tailored solutions that address your specific challenges and goals.</p>
        </div>
    </div>
    <div className='col-lg-6 col-xxl-3  col-sm-12 ' id='exceptionalstr' >
    <div className='p-5' id='productoptim'>
        <img src={productoptimization} alt=''/>
        <p className='fixproduct my-3'>Quality Assurance</p>
        <p className='formonthlybased'>We adhere to industry best practices and rigorous quality assurance processes to ensure the software we deliver is of the highest quality and meets.</p>
        </div>
    </div>
    <div className='col-lg-6 col-xxl-3  col-sm-12 ' id='exceptionalstr' >
    <div className='p-5' id='productcons'>
        <img src={productoptimization} alt=''/>
        <p className='fixproduct my-3'>Transparent Communication</p>
        <p className='formonthlybased'>We maintain open and transparent communication throughout the development process, providing regular updates.</p>
        </div>
    </div>
</div>
</div>
    </div>
    </div>
  )
}

export default WhyChooseUs