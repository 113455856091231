import React from "react";
import arrow from "../../assets/double-arrow.svg";
import "./Technical.css";
import open from "../../assets/open.svg";
import experence from "../../assets/experience.svg";
import fresher from "../../assets/fresher.svg";
import { Link } from "react-router-dom";

function JobCard({ image, title, experience, description ,experid,className,MainM}) {
  return (
 

    <div className={`col-lg-4 col-md-6 col-sm-12 ${MainM}`}>
      <div className="rounded-4 bg-faf8 p-40 jobbox">
        <img src={image} alt={title} />
        <p className="mt-4 frontdevelu">{title}</p>
        <div className="my-4 " id={experid}>
          <p className={className}>{experience}</p>
        </div>
        <p className="getanunderstaof">{description}</p>
        <div className="pt-40">
        <Link to="/job-application-form" >
        <button className="col-12 submitresu">
            <span className="submityrrsm">Submit Your Resume</span>
            <img src={arrow} alt="" />
          </button>
        </Link>
        
        </div>
      </div>
    </div>
   
  );
}


function Technical() {
  const jobData = [
    {
      image: fresher,
      title: "Front-End Developer",
      experience: "Fresher",
      description: "Get an understanding of the industry from the basics and build a stepping stone for your career.",
      experid:"fresherfront",
      className:"fresherpe"
    },
    {
      image: experence,
      title: "Web Developer",
      experience: "Experience",
      description: "Showcase your years of experience and knowledge and step forward for your growth.",
      experid:"webdevelopex",
      className:"webdevcol",
      MainM:"exp-main"
    },
    {
      image: open,
      title: "Tester",
      experience: "0-3 Yr Experience",
      description: "Shape your career with us and get access to top industry experts and professionals.",
      experid:"testeryr",
      className:"testcol",
      MainM:"test-main"
    },
  ];

  return (
    <div className="container titislook">
      <div className="text-center">
        <p className="mb-40 riralooki text-center">Rirabh is Looking for You</p>
        <p className="wanttotech">
          Want to kick-start your career as a junior? Or move up the ladder as a
          veteran? Or maybe pivot to new technology or career path? We might
          have the right opportunity for you. Below are the open positions at
          Techuz. (In case you're wondering, it’s pronounced Tech-us.)
        </p>
      </div>
      <div className="row pt-40 mb-100">
        {jobData.map((job, index) => (
          <JobCard
            key={index}
            experid={job.experid}
            className={job.className}
            image={job.image}
            title={job.title}
            experience={job.experience}
            description={job.description}
            MainM={job.MainM}
          />
        ))}
      </div>
    </div>
  );
}

export default Technical;
