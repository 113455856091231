import React from 'react';
import './BusinessSolution.css';
import { Link } from 'react-router-dom';

function BusinessCard({ card }) {
  return (
    <div className="container ">
      <div className="row">
        <div className="col-lg-6 col-sm-12" id="knowmore">
          <p  id="cardname">{card.name}</p>
          <p className="mt-4 " id="cardarticlename">{card.articlename}</p>
          <p className="mt-3 " id="cardrecentwork">{card.recentwork}</p>
          <ul className="mt-3 " id="cardachievement">
            <li className='mt-2'>{card.achievement1}</li>
            <li className='mt-2'>{card.achievement2}</li>
          </ul>
          <Link to={card.link}>
          <button className="mt-2 " id="knowmorebusiness">Know more</button>
          </Link>
        </div>
        <div className="col-lg-6 col-sm-12 " id="ladiescoffee">
          <img src={card.image} alt={card.name} className='img-fluid' id='ladiespadding'/>
        </div>
      </div>
    </div>
  );
}

export default BusinessCard;
