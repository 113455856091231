import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import YourProject from "../component/YourProject/YourProject";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import wd1 from "../../src/assets/wd1.svg";
import wd2 from "../../src/assets/wd2.svg";
import wd3 from "../../src/assets/wd3.svg";
import wd4 from "../../src/assets/wd4.svg";
import { Helmet } from "react-helmet";
function WebDevelopment() {
  return (
    <div>
      <Helmet>
        <title>
        Best Web Development Services in India | Rirabh
        </title>
        <meta
          name="description"
          content="Explore top web development services in India offering custom solutions,
           from responsive design to robust e-commerce platforms. Expert developers, quality results"
        />
        <meta name="keywords" content="web development services, web  development services near me, web development company, web development services in India" />
      </Helmet>
   
      <CustomSDev
        heading="Web Development Service"
        para="Scale Your Business to the next level through our highly popular web development facilities "
      />
      <CusSoftDevelopment
        MainHeading="WE OFFER "
        HPara="Highly trusted and experienced web development services   "
        h1="Website Designing:  "
        p1="As a Website design company, Our team of experts creates visually stunning.  "
        h2="Digital Marketing :"
        p2="Harness the power of our strategic digital marketing solutions including SEO, social media, PPC, and content marketing.     "
        h3=" Graphic Designing:"
        p3=" Our talented graphic designers bring creativity, Engaging, and visually appealing user experiences.   "
        h4=" 100% Responsive:"
        p4="Get your device responsive website design to reach your potential audience across all devices."
        img1={wd4}
        img2={wd3}
        img3={wd2}
        img4={wd1}
      />
      <ProcessDevelop
        heading="Our Process For Web App Development"
        para="The web development process follows a structured approach called the web development life cycle. "
        name1="Consultation and Strategy"
        name2="Design and Prototyping"
        name3="Testing and Quality Assurance"
        name4="Maintenance and Support"
        name5="SEO and Digital Marketing"
      />
      <WhyChooseUs />
      <YourProject />
    </div>
  );
}

export default WebDevelopment;
