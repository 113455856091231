import React from 'react';
import { Link } from 'react-router-dom';
import LOGO from '../../assets/NAVLOGO.svg';

const Logo = ({ classname }) => {
  return (
    <Link to="/">
      <img className={classname} src={LOGO} alt="Logo" />
    </Link>
  );
};

export default Logo;
