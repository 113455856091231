import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import ss1 from "../../src/assets/ss4.svg";
import ss2 from "../../src/assets/ss3.svg";
import ss3 from "../../src/assets/ss2.svg";
import ss4 from "../../src/assets/ss1.svg";
import { Helmet } from "react-helmet";

function SMSSellingPortal() {
  return (
    <div>
  <Helmet>
        <title>
        Best Bulk Sms Service Provider in India | Rirabh
        </title>
        <meta
          name="description"
          content="Find Out the top SMS providers in India delivering reliable, fast, and affordable messaging services to boost your business communication and engagement."/>
        <meta name="keywords" content="top 10 bulk sms service providers in india, top 5 bulk sms service providers in india, sms providers in India, best sms providers in india" />
      </Helmet>


    <CustomSDev
      heading="SMS Selling Portal" para="Increase Your Reach with SMS Selling Portal "
    />
        <CusSoftDevelopment MainHeading="WE OFFER"
        HPara="A leading provider of business messaging solutions."
        h1="High-Speed SMS Delivery:  "
        p1="High-Speed Priority Route SMS Delivery is our key USP.   "
        h2="Bulk SMS Service: "
        p2="You can now send 1 Lac SMS in just 30 Minutes "
        h3="Credit Based Plans:  "
        p3="We offer economic plans that fit all types of customers' budgets. "
        h4="Get Detailed Report:   "
        p4="Get system system-generated detailed report post each campaign completion automatically. "
        img1={ss1}
        img2={ss4}
        img3={ss3}
        img4={ss2}
      />
           <ProcessDevelop
        heading="SMS Selling Portal Implementation Process"
        para="SMS Selling Portal solution involves several key steps Such as:"
        name1="Assessment and Planning"
        name2="Development and Customization"
        name3="Testing"
        name4="Deployment"
        name5="Training and Documentation"
      />
      <WhyChooseUs/>
    </div>
  );
}

export default SMSSellingPortal;
 