import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AboutScroll.css";
import {  FaStar } from "react-icons/fa";
import { IoIosArrowForward ,IoIosArrowBack } from "react-icons/io";
import testimonial from "../../assets/testimonial.jpeg";
import testimonial4 from "../../assets/testimonial4.png";
import noneOne from "../../assets/noneone.jpg"
import deepak from "../../assets/6p.png"
import { FaQuoteLeft,FaQuoteRight } from "react-icons/fa";

const RirabhTestimonial = [
  {
    id: 1,
    name: "Himanshu Ratnakar",
    photo: testimonial,
    designation: "Developer",
    ReviewHeading: "It was very good experience",
    review:
      "I will appreciate the work of the Rirabh team. They always deliver to the client’s expectations. We have a great working relationship with them. ",
    rating: 5,
  },
  {
    id: 2,
    name: "Deepak",
    photo: deepak,
    designation: "Content Strategist",
    ReviewHeading: "Amazing customer service",
    review:
      "I am Really impressed with the quality of the work, dedication, and project management of the Rirabh team. They take good care in providing leadership to the team that works for us.",
    rating: 5,
  },
  {
    id: 3,
    name: "Joshua Young",
    photo: noneOne,
    designation: "Chief Operating Officer",
    ReviewHeading: "Professional and Responsive Team",
    review:
      "Rirabh has some of the best developers in India. Their service is strong & reliable as well as the communication is transparent. It’s brilliant to work with this team.",
    rating: 5,
  },
  {
    id: 4,
    name: "Mark Wang",
    photo: testimonial4,
    designation: "Chief Executive Officer",
    ReviewHeading: "Delivered Results Beyond Compare",
    review:
      "I am Really impressed with the quality of the work, dedication, and project management of the Rirabh team. They take good care in providing leadership to the team that works for us.",
    rating: 4,
  },
  {
    id: 5,
    name: "Akancha singh",
    photo: noneOne,
    designation: "Brand Consultant",
    ReviewHeading: "Reliable and Professional Service",
    review:
      "I got everything in time and also, the developers established good communication and provided the best effort to deliver work as per my expectations. ",
    rating: 4,
  },
];

function Testimonial() {
  const NextArrow = ({ onClick }) => (
    <button className="slick-arrow slick-next p-3 " onClick={onClick} >
      <IoIosArrowForward  className="arrowforward" style={{color:"#FF7A30"}}/>
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button className="slick-arrow slick-prev  p-3" onClick={onClick} >
      <IoIosArrowBack  className="arrowbackward" style={{color:"#FF7A30"}}/>
    </button>
  );

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="mt-100 pb-100 pt-100 container slick-testimonial">
      <h2 className="mb-40 clienttesti">What Our Clients Say About Us</h2>
      <div className="m-40">
        <div className="slider-container">
          <Slider {...settings}>
            {RirabhTestimonial.map((testimonial) => (
              <div key={testimonial.id}>
                <div className="testimonial-card ">
                <div className="d-flex justify-content-between mt-2">
                <div className="d-flex">
                  <div className="testimonial-header">
                    <img src={testimonial.photo} alt="Client" />
                    </div>
                    <div className="testinmeigna">
                      <p className="testinmeignap">{testimonial.name}</p>
                      <span className="testinmeignapd">{testimonial.designation}</span>
                    </div>
                    </div>
                    <div>
                      <div className="testimonial-stars">
                        {[...Array(testimonial.rating)].map((_, starIndex) => (
                          <FaStar key={starIndex} />
                        ))}
                      </div>
                      </div>
                    </div>
                  

                    {/* <h4 className="mt-4 testinmeignap">{testimonial.ReviewHeading}</h4> */}
                  <div className="testimonial-content">
                    <p className="testireview pt-40"><FaQuoteLeft className="testimonial-quo-left"/>{testimonial.review}<FaQuoteRight className="testimonial-quo-right"/></p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
