import React, { useEffect, useRef, useState } from "react";
import "./AboutScroll.css";

function Aboutscroll() {
  const [activeSection, setActiveSection] = useState("");
  // eslint-disable-next-line
  const sectionRefs = {
    first: useRef(null),
    second: useRef(null),
    third: useRef(null),
    fourth: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionRefs]);

  return (
    <div className="background-sCROLL ">
      <div className="live_by_container container">
        <div className="row">
          <h1 className="text-white mt-40" id="overlay-text"> How we Work?</h1>
          <div className="col-lg-2"></div>
          <div className="sticky_side_sldr col-2 col-lg-1" id="myScrollspy">
            <ul className="scrollspy text-right">
              <li className="nav-item ">
                <a
                  className={`nav-link ${
                    activeSection === "first" ? "active" : ""
                  }`}
                  href="#first"
                >
                  01
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className={`nav-link ${
                    activeSection === "second" ? "active" : ""
                  }`}
                  href="#second"
                >
                  02
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className={`nav-link ${
                    activeSection === "third" ? "active" : ""
                  }`}
                  href="#third"
                >
                  03
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className={`nav-link ${
                    activeSection === "fourth" ? "active" : ""
                  }`}
                  href="#fourth"
                >
                  04
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-1" id="hide-on-small"></div>
          <div className="content-container col-10 col-lg-7">
          <div className={`fostersolve ${activeSection === "first" ? "active-content" : ""}`} id="first" ref={sectionRefs.first}>
  <h2 className="whotr">Scalability & Support</h2>
  <p className="whotr">
    We work with you based on your objectives to bring you the most value and the quickest return on investment while defining tactics and a dedicated team to your project.
  </p>
</div>
<div className={`fostersolve ${activeSection === "second" ? "active-content" : ""}`} id="second" ref={sectionRefs.second}>
  <h2 className="whotr">Industry-Specific Experience</h2>
  <p className="whotr">
    Our experienced developers are organized in virtual divisions, generate a good strategy, and offer exceptional application development solutions.
  </p>
</div>
<div className={`fostersolve ${activeSection === "third" ? "active-content" : ""}`} id="third" ref={sectionRefs.third}>
  <h2 className="whotr">Real-Time Communication</h2>
  <p className="whotr">
    Our developers work within a specific period to allow real-time collaboration and establish good communication with the client. 
  </p>
</div>
<div className={`fostersolve ${activeSection === "fourth" ? "active-content" : ""}`} id="fourth" ref={sectionRefs.fourth}>
  <h2 className="whotr">Complete Transparency</h2>
  <p className="whotr">
    We establish complete transparency with our clients and we allow clients insights into project progress and timelines.
  </p>
</div>

          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </div>
  );
}

export default Aboutscroll;
