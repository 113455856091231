import React from "react";
import aimarrow from "../../assets/targetArrow.png";
import frame146 from "../../assets/Frame146.svg";
import frame147 from "../../assets/Frame147.svg";
import rocket from "../../assets/rocket.svg";

function OurMission() {
  return (
    <div className="container-fluid whobg">
      <div className="container">
        <div className="text-center">
          <img src={aimarrow} alt="Aim Arrow" className="" />
          <h3 className="ourmission">Our Mission</h3>
          <p className="ouraimtochange">
            Our main mission is to deliver our promises and fulfill the
            expectations of the client.
          </p>
        </div>
        <div className="employee-container mb-40">
          <div className="employeeborder employee1">
            <div className="p-lg-40 d-lg-flex align-self-center ">
              <img
                src={frame146}
                alt="Employe Centric"
                className="img-fluid "
                style={{display:"block",margin:"auto"}}
              />
              <div className="rirabhmakr align-self-center">
                <h4 className="employecentric">From Director Desk:</h4>
                <p className="alongsidwork mt-4">
                  I am proud to introduce you to a company dedicated to creating
                  exceptional solutions that drive business success and deliver
                  outstanding user experiences.
                </p>
              </div>
            </div>
          </div>
          <div className="employeeborder employee2">
            <div className="p-lg-40 d-lg-flex ">
              <img
                src={frame147}
                alt="Employe Centric"
                className="img-fluid"
                style={{display:"block",margin:"auto"}}
              />
              <div className="rirabhmakr align-self-center">
                <h4 className="employecentric">From Manager:</h4>
                <p className="alongsidwork mt-4">
                  Our journey began with a simple yet powerful belief: our
                  digital world offers limitless possibilities, and we are here
                  to help through our full potential.
                </p>
              </div>
            </div>
          </div>
          <div className="employeeborder employee3">
            <div className="row p-40">
              <div className="col-lg-6 col-12 align-self-center">
                <div>
                  <h3 className="ourrivisi">Our Vision</h3>
                  <p className="alongsidwork mt-4">
                    Our vision is to create and provide an exceptional digital
                    experience that can resolve business challenges without any
                    barriers.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <img src={rocket} alt="" className="img-fluid w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurMission;
