import React from "react";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import CustomSDev from "../component/Parts/CustomSDev";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import vb1 from "../../src/assets/vb1.svg";
import vb2 from "../../src/assets/vb2.svg";
import vb3 from "../../src/assets/vb3.svg";
import vb4 from "../../src/assets/vb4.svg";
import { Helmet } from "react-helmet";
function VoIPBilling() {
  return (
    <div>
  <Helmet>
        <title>
        Top VoIP Solution Providers in India | Rirabh
        </title>
        <meta
          name="description"
          content="Explore the leading VoIP solution providers in India offering reliable, cost-effective communication services to enhance business efficiency and connectivity."/>
        <meta name="keywords" content="Top 10 VoIP providers, best voip service provider in india, voip service providers near me, voip service providers for international calling" />
      </Helmet>


    <CustomSDev
      heading="VoIP Billing" para="Reduce the invoicing and billing processes via VoIP business. "
    />
      <CusSoftDevelopment MainHeading="WE OFFER"
        HPara="Get the efficiency manage billing, payment processing, and reporting."
        h1="Increase Efficiency and Accuracy: "
        p1="This billing system for VoIP businesses automates the whole process.   "
        h2="Complete Control Over Business: "
        p2="Get access to all reports and processes along with payment history"
        h3="Single System For All Billing Needs:  "
        p3="Single VoIP billing software solution will handle all jobs efficiently. "
        h4="Scalability Without Complexity :  "
        p4="As your business expands, our VoIP Billing Software scales effortlessly. "
        img1={vb4}
        img2={vb3}
        img3={vb2}
        img4={vb1}
      />
           <ProcessDevelop
        heading="VoIP Billing Implementation Process"
        para="VoIP Billing solution involves several key steps Such as:"
        name1="Call Data Collection"
        name2="Billing Cycle Management"
        name3="Invoice Generation"
        name4="Payment Processing"
        name5="Reporting and Analytics"
      />
      <WhyChooseUs/>
    </div>
  );
}

export default VoIPBilling;


