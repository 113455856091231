import React from 'react';

function Pagenotfound() {
  return (
    <div 
      className='container text-center container-page-not-found' 
      style={{ height: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <h1 className='linebusiness'>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}

export default Pagenotfound;
