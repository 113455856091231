import React from 'react';
// import customsoftimg from "../../assets/customsoftimg.svg";

function CommCard({ heading1, heading2, para1, para2, color1, color2 ,img1,img2,alt1,alt2}) {
  return (
    <div className="row">
      <div className="col-12 col-lg-6 padddingrowe">
        <div className="row rounded-4" style={{ backgroundColor: color1 }}>
          <div className="col-md-6 col-sm-12 align-self-center">
            <p className="ecommercecus mt-sm-100">{heading1}</p>
            <p className="fromcusimple ">{para1}</p>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-6 d-flex justify-content-end">
            <img src={img1} alt={alt1} className="marginrightimage" />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 paddding2rowe">
        <div className="row rounded-4" style={{ backgroundColor: color2 }}>
          <div className="col-12 col-md-6 align-self-center">
            <p className="ecommercecus mt-sm-100">{heading2}</p>
            <p className="fromcusimple ">{para2}</p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-end">
            <img src={img2} alt={alt2} className="marginrightimage" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommCard;
