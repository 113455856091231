import React from "react";
import "./cusSoftDevelopment.css";
import requirementanalysis from "../../assets/RequirementAnalysis.svg";
import designandplanning from "../../assets/Design&Planning.svg";
import development from "../../assets/Development.svg";
import testingandqualify from "../../assets/Testing&QualityAssurance.svg";
import deployement from "../../assets/Deployment&Support.svg";
import one from "../../assets/1.svg"
import two from "../../assets/2.svg"
import three from "../../assets/3.svg"
import four from "../../assets/4.svg"
import five from "../../assets/5.svg"



function SoftwareCard({ name, img }) {
  return (
    <div className="col-sm-6 col-md-4 col-lg-2 mb-4">
      <div className="bordersoftcard">
        <div className='rweqsr'>
          <img src={img} alt={name} />
        </div>
        <div className="d-flex justify-content-center">
          <div >
            <p className="reqdevdes mt-40 mb-40">{name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProcessDevelop({heading,name1,name2,name3,name4,name5,para}) {
  return (
    <div className="bgProcess">
      <h2 className="softwarep">{heading}</h2>
      <p className="Gomilestone">
   {para}
      </p>
      <div className="mt-40  container">
        <div className="row justify-content-between">
          <SoftwareCard name={name1} img={requirementanalysis} />
          <SoftwareCard name={name2} img={designandplanning} />
          <SoftwareCard name={name3} img={development} />
          <SoftwareCard name={name4} img={testingandqualify} />
          <SoftwareCard name={name5} img={deployement} />
        </div>
      </div>
      <div className="d-flex justify-content-between container " id="onetwothree">
        <img src={five} alt=""/>
        <img src={four} alt=""/>
        <img src={three} alt=""/>
        <img src={two} alt=""/>
        <img src={one} alt=""/>
      </div>
    </div>
  );
}

export default ProcessDevelop;
