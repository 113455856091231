import React from 'react';
import './navbar.css'
import Toggleshow from './Toggleshow';
import Logo from './Logo';
import Telephonecall from './Telephonecall';
import ListItem from './ListItem';


function NavbarCom() {

  return (
    <>
      <nav className="navbar navbar-expand-lg  navbarcomone " id='hide-on-small' > 
        <div className="container-fluid containernav">
          <Logo classname="navlogomar"/>
          <ListItem />
          <Telephonecall clasname="navbutton" />
        </div>
      </nav>
      <Toggleshow />
    </>
  );
}

export default NavbarCom;
