import React from "react";
import CustomSDev from "../component/Parts/CustomSDev";
import CusSoftDevelopment from "../component/SoftwareDevelopment/cusSoftDevelopment";
import Technology from "../component/TechnologiesWork/Technology";
import WorkingModel from "../component/WorkingModel/WorkingModel";
import WhyChooseUs from "../component/SoftwareDevelopment/WhyChooseUs";
import LetgetStart from "../component/SoftwareDevelopment/LetgetStart";
import ProcessDevelop from "../component/SoftwareDevelopment/ProcessDevelop";
import YourProject from "../component/YourProject/YourProject";
import cs1 from "../.././src/assets/cs1.svg";
import cs2 from "../.././src/assets/cs2.svg";
import cs3 from "../.././src/assets/cs3.svg";
import cs4 from "../.././src/assets/cs4.svg";
import { Helmet } from "react-helmet";
function SoftwareDevelopment() {
  return (
    <div>
      <Helmet>
        <title>
          Top Software Development Services: Custom Solutions & Expertise |
          Rirabh
        </title>
        <meta
          name="description"
          content="Discover top-tier software development services offering customized solutions to meet your business needs. Expert team, innovative technology, and reliable support."
        />
        <meta
          name="keywords"
          content="Software development services, Software development services provider, Custom software development, Software solutions, Software development company, Software development India, Custom software solutions, Software application development, Enterprise software development, Best software development services, Innovative software solutions, Software consulting, Software outsourcing services"
        />
      </Helmet>

      <CustomSDev
        heading="Custom Software Development"
        para="Reimagine your business into a software-first business with our Software Development Approach "
      />
      <CusSoftDevelopment
        MainHeading="WE OFFER"
        HPara="Software quality guaranteed & Consistent delivery with the agile approach  "
        h1="Design, Development & Prototyping: "
        p1="We design user-friendly, secure websites with responsive & dynamic interfaces.  "
        h2="Deployment and Implementation: "
        p2="Our deployment makes the smooth transition and secure migration of the data.    "
        h3=" Client Training and Documentation:"
        p3=" We conduct special training sessions and demonstrate everything more easily.  "
        h4="Unique Software Solutions:  "
        p4="We enable businesses across the globe to scale and transform."
        img1={cs4}
        img2={cs3}
        img3={cs2}
        img4={cs1}
      />
      <ProcessDevelop
        heading="Our Process For Software Development"
        para="The software development procedure is the right methodology to create & manage software that involves several steps. "
        name1="Conceptualization and Consultation"
        name2="Design and Development"
        name3="Testing and Quality Assurance"
        name4="Integration and Deployment"
        name5="Maintenance and Support"
      />
      <WhyChooseUs />
      <Technology />
      <LetgetStart />
      <WorkingModel />
      <YourProject />
    </div>
  );
}

export default SoftwareDevelopment;
