import React from 'react'
import CustomSDev from '../component/Parts/CustomSDev'
import Hidebutton from '../component/Career/Hidebutton'
import gallery from '../assets/Gallery.svg'
import GalleryDesign from '../component/Gallery/GalleryDesign'
import { Helmet } from 'react-helmet'

function Gallery() {
  return (
    <div>
   <Helmet>
  <title>Gallery | Rirabh - Explore Our Work and Culture</title>
  <meta
    name="description"
    content="Discover the vibrant and dynamic culture at Rirabh. Our gallery showcases the creativity, teamwork, and innovation that define our company."
  />
  <meta
    name="keywords"
    content="Rirabh gallery, Rirabh team, Rirabh culture, Rirabh events, Rirabh work environment, Rirabh employee life, company culture, Rirabh projects, Rirabh office"
  />
</Helmet>

             <CustomSDev
        heading="Gallery"
        para="Each piece in this gallery tells our story."
      />
      <Hidebutton
        heading="We love building Exceptional Products."
        para="Well, that’s what drives us to our desks every day — building products that delight users, that solve business challenges, that make the world a better place, and that deliver promises. And, yes, we can do it for you too."
        image={gallery}
      />
      <GalleryDesign/>
    </div>
  )
}

export default Gallery