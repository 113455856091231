import React from "react";
import { FaAlignRight } from "react-icons/fa6";
import Logo from "./Logo";
import Listitemsm from "./Listitemsm";

//npm install react-icons --save

export default function Toggleshow(){
    return(
        <>
  <div className="container-fluid d-flex justify-content-between bg-black d-lg-none py-3 sticky-top">
    <Logo/>
    <button type="button" className="border-0 bg-transparent" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight">
    <FaAlignRight style={{ color: 'white' }} />
    </button>
      <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header bg-">
          <h5 className="offcanvas-title" id="offcanvasRightLabel"><Logo/></h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
          </button>
        </div>
        <div className="offcanvas-body bg-">
        {/* body */}
    <Listitemsm/>
        </div>
      </div>
    </div>
        </>
    )
}